import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGetCategoriesQuery } from '../../../services/categoryApi'
import { therapistActions } from '../../../services/therapistSlice'
// import { bubblesData } from './bubbles data'
import { BASE_URL } from '../../../keys'

const Bubble = ({ handleClick }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //eslint-disable-next-line
    const { data, isSuccess, isLoading, isError } = useGetCategoriesQuery()
    // console.log('Categories', data?.data)

    return (
        <>
            <div className=" flex gap-5 top-0 right-[4%] ">
                {isSuccess &&
                    data?.data?.map((item, index) => (
                        <div
                            key={index}
                            className={`categoryPillar relative w-[180px] h-[65vh] group ${
                                item.is_active === 0 && 'disabledVertical'
                            }`}
                        >
                            <div
                                className={`categoryPillarInnerWrap w-full h-[65vh] relative overflow-hidden rounded-b-[100px] cursor-pointer z-[2]`}
                                style={{
                                    backgroundColor: `${item.color_code}`,
                                }}
                                onClick={() => {
                                    if (item.is_active === 1) {
                                        navigate(
                                            '/findtherapist/' +
                                                item.name +
                                                '?' +
                                                item.id
                                        )
                                        dispatch(
                                            therapistActions.setBG(
                                                `${item.color_code}`
                                            )
                                        )
                                        dispatch(
                                            therapistActions.setCategoryIcon(
                                                `${item.logo}`
                                            )
                                        )
                                        dispatch(
                                            therapistActions.setCategoryName(
                                                `${item.name}`
                                            )
                                        )
                                        //Maintain this state as empty apart from UpComingSessions
                                        dispatch(
                                            therapistActions.setCoachIdBN('')
                                        )
                                        dispatch(
                                            therapistActions.setBtnClick('no')
                                        )
                                    }
                                }}
                            >
                                <h1 className="text-2xl font-bold font-ubuntu w-full text-center mt-20 min-h-[64px] flex justify-center items-center">
                                    {item.name}
                                </h1>
                                <p className="font-ubuntu w-10/12 mx-auto my-5 text-center text-sm">
                                    {item.details}{' '}
                                </p>
                                {item.id === 4 && (
                                    <img
                                        src={BASE_URL + item.logo}
                                        className="absolute bottom-[20px] w-10/12 h-10/12"
                                        alt="pillar"
                                    />
                                )}
                                {item.id === 3 && (
                                    <img
                                        src={BASE_URL + item.logo}
                                        className="absolute bottom-[20px] w-12/12 h-10/12"
                                        alt="pillar"
                                    />
                                )}
                                {item.id === 2 && (
                                    <img
                                        src={BASE_URL + item.logo}
                                        className="absolute bottom-[20px] w-11/12 h-10/12 right-[4%]"
                                        alt="pillar"
                                    />
                                )}
                                {item.id === 1 && (
                                    <img
                                        src={BASE_URL + item.logo}
                                        className="absolute bottom-[0] w-12/12 h-10/12"
                                        alt="pillar"
                                    />
                                )}
                            </div>
                            {/* <div
                                className={`activeLine w-[100px] h-[3px] absolute bottom-[30px] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1]`}
                                style={{
                                    backgroundColor: `${item.color_code}`,
                                }}
                            ></div> */}
                        </div>
                    ))}
            </div>
        </>
    )
}

export default Bubble
