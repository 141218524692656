import React, { useEffect, useRef, useState } from 'react'
import Input from '../../Login/Input'
import searchIcon from '../../../assets/images/search-icon.png'
import SimpleBar from 'simplebar-react'
import { coachData } from './dummyCoachData'
import { useGetUsersInChatQuery } from '../../../services/chatApi'
import { useDispatch, useSelector } from 'react-redux'
import { coachActions } from '../../../services/coachSlice'
import { useGetCurrentUserQuery } from '../../../services/authApi'
import SessionExpired from '../../Common/SessionExpired'
import { useParams } from 'react-router-dom'
import api from '../../../services/api'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import { BASE_URL } from '../../../keys'
import { useGetCategoriesQuery } from '../../../services/categoryApi'

const Left = ({ socket }) => {
    const { coach__id } = useParams()
    const { data, isLoading, isSuccess } = useGetUsersInChatQuery()
    const Ref = useRef(null)
    const [listOfClients, setListOfClients] = useState([])

    const { data:categoriesData } = useGetCategoriesQuery()
    const [categories, setCategories] = useState(categoriesData?.data)
    

    // console.log('@@@@@@DATA', data?.data)
    // console.log(
    //     '@@@@@@FILTER',
    //     data?.data?.filter((data) => data.coach_id == coach__id)
    // )

    const dispatch = useDispatch()
    const coachName = useSelector((state) => state.coachReducer.coachName)
    const {
        data: currentUser,
        isLoading: userLoading,
        isError: userError,
    } = useGetCurrentUserQuery()
    // console.log(`userError ${userError}`)
    const user = currentUser?.data
    const roomId = useSelector((state) => state.coachReducer.roomId)
    const coach_category = useSelector(
        (state) => state.coachReducer.coach_category
    )
    // console.log(`current coach category: ${coach_category}`);
    // console.log(`currently selected room ${roomId}`)
    //const [lastMsg, setlastMsg] = useState('')
    const [userList, setuserList] = useState([])
    const joinRoom = () => {
        // console.log('inside socket', roomId)
        socket.emit('join_room', roomId)
    }
    // console.log(roomId, "roomId");
    useEffect(() => {
        joinRoom()
    }, [roomId])

    useEffect( ()=>{
        setCategories(categoriesData?.data) 
        // console.log("CATTTTTTTTTT", categoriesData?.data)
    },[])
    
    //updating latest chat user to the top
    // useEffect(() => {
    //     socket.on('message_triggered', (data) => {
    //         console.log("message_triggerd in client*****")
    //         api.get(`common/list-of-users-in-chat/`, {
    //             headers: {
    //                 authorization: `Bearer ${localStorage.getItem('livtoken')}`,
    //             },
    //         }).then((res) => {
    //             setListOfClients(res?.data.data)
    //             dispatch(
    //                 coachActions.toggleCoach({
    //                     name: res?.data.data[0].coach_name,
    //                     roomId: `${res?.data.data[0].coach_email}_${user?.email}`,
    //                     coach_category: res?.data.data[0].coach_category,
    //                     coach_id: res?.data.data[0].coach_id,
    //                     sessionDate: res?.data.data[0].session_date,
    //                     sessionTime: res?.data.data[0].session_time,
    //                     isRelationPaused: res?.data.data[0].is_relation_paused,
    //                     coach_category_id: res?.data.data[0].coach_category_id,
    //                     last_message: res?.data.data[0].last_message.message
    //                 })
    //             )
    //         })
    //     })
    // }, [socket])

    //triggering this api in every 5 seconds because there is no socket event in the django backend for updating the user (temp fix)
    useEffect(() => {
        const listUsers = setInterval(() => {
            api.get(`common/list-of-users-in-chat/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('livtoken')}`,
                },
            }).then((res) => {
                setListOfClients(res?.data.data)
            })
        }, 5000);
        return () => clearInterval(listUsers);
    }, [listOfClients]);

    useEffect(() => {
        //console.log(data?.data[0].coach_email + "_" + data?.data[0].user_email, "data*****")
        dispatch(
            coachActions.toggleCoach({
                roomId: data?.data[0].coach_email + '_' + user?.email,
            })
        )
        setuserList(data?.data)
        setListOfClients(data?.data)
    }, [data])

    // select specific coach after successful booking - redirected from findtherapist
    useEffect(() => {
        if (coach__id) {
            api.get(`common/list-of-users-in-chat/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('livtoken')}`,
                },
            }).then((res) => {
                res?.data?.data
                    ?.filter((data) => data.coach_id == coach__id)
                    .map((item) => {
                        dispatch(
                            coachActions.toggleCoach({
                                name: item.coach_name,
                                roomId: `${item.coach_email}_${user?.email}`,
                                coach_category: item.coach_category,
                                coach_id: item.coach_id,
                                sessionDate: item.session_date,
                                sessionTime: item.session_time,
                                isRelationPaused: item.is_relation_paused,
                                coach_category_id: item.coach_category_id,
                                booking_id: data?.data[0].last_message.booking_id
                            })
                        )
                    })
            })
            setTimeout(scrollToChat, 1000)
        }
    }, [coach__id, data])

    //redirected from booking module
    useEffect(() => {
        if (coach__id) {
            api.get(`common/list-of-users-in-chat/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('livtoken')}`,
                },
            }).then((res) => {
                setListOfClients(res?.data?.data)
            })
            setTimeout(scrollToChat, 1000)
        }
    }, [coach__id])

    // Autoselect first coach from the list when chat page is visited
    useEffect(() => {
        if (!coach__id) {
            dispatch(
                coachActions.toggleCoach({
                    name: data?.data[0].coach_name,
                    roomId: `${data?.data[0].coach_email}_${user?.email}`,
                    coach_category: data?.data[0].coach_category,
                    coach_id: data?.data[0].coach_id,
                    sessionDate: data?.data[0].session_date,
                    sessionTime: data?.data[0].session_time,
                    isRelationPaused: data?.data[0].is_relation_paused,
                    coach_category_id: data?.data[0].coach_category_id,
                    last_message: data?.data[0].last_message
                })
            )
        }
    }, [data])

    //Client search
    const searchFilter = (e) => {
        setListOfClients(
            data?.data.filter((item) =>
                item.coach_name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        )
    }

    //call this function once the redirection happening
    const scrollToChat = () => {
        Ref.current?.scrollIntoView({ alignToTop: true, behavior: "smooth", block: "nearest", inline: "nearest" })
    }

    function findCategoryColor(id) {
        const obj = categoriesData?.data?.find(i => i.id === id)
        // return `bg-[${obj?.color_code}]`
        return `${obj?.color_code}`
    }

    return (
        <aside className="w-[350px] p-4 h-[92%] mt-12 ml-8">

            <h1 className="text-xl">Last Chats</h1>

            {/* Text Search */}
            <div className="flex bg-white rounded-3xl py-2 px-4 outline-none border-[#FFC289] border mt-3">
                <div className="w-[30px] mt-1">
                    <img src={searchIcon} alt="" className="h-5" />
                </div>
                <input
                    className="outline-none w-full"
                    name="search-text"
                    id="search-text"
                    placeholder="Search"
                    onChange={(e) => {
                        searchFilter(e)
                    }}
                />
            </div>

            <SimpleBar className='lg:h-[50vh] xl:h-[72vh] 2xl:h-[77vh] 3xl:h-[80vh]' forceVisible="y" autoHide={false}>
                <div className="w-full">
                    {isLoading && (
                        <>
                            {coachData.map((_, i) => (
                                <div
                                    key={i}
                                    className="border bg-heavy border-heavyBorder shadow rounded-md p-4 max-w-sm w-full mx-auto my-3"
                                >
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="rounded-full bg-heavyBorder h-10 w-10"></div>
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="h-2 bg-heavyBorder rounded"></div>
                                            <div className="space-y-3">
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                                    <div className="h-2 bg-heavyBorder rounded col-span-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {!isLoading && listOfClients?.length === 0 && (
                        <p className="mt-40 text-center">No Coach Found!</p>
                    )}
                    {!isLoading &&
                        isSuccess &&
                        listOfClients?.map((item, index) => (
                            <div
                                key={item.id}
                                onClick={() => {
                                    dispatch(
                                        coachActions.toggleCoach({
                                            name: item.coach_name,
                                            roomId: `${item.coach_email}_${user?.email}`,
                                            coach_category: item.coach_category,
                                            coach_id: item.coach_id,
                                            sessionDate: item.session_date,
                                            sessionTime: item.session_time,
                                            isRelationPaused:
                                                item.is_relation_paused,
                                            coach_category_id:
                                                item.coach_category_id,
                                            last_message: item.last_message
                                        })
                                    )
                                }}
                                className={`flex rounded-[50px] w-full p-1 mt-5 ${coachName === item.coach_name
                                    ? 'bg-white'
                                    : ''
                                    }`}
                            >
                                {/* here added a refrence which will only create for the chat which is selected */}
                                {item.coach_id == coach__id ?
                                    <div ref={Ref}></div> : null
                                }
                                <div className="flex rounded-[40px] cursor-pointer items-center w-full p-3">
                                    <div className="relative flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-heavy border-heavyBorder ">
                                        <img
                                            src={`${item.coach_profile_pic}` != 0 ?
                                                `${BASE_URL}${item.coach_profile_pic}` :
                                                `${emptyavatar}`}
                                            alt="profile image"
                                            className={item.is_relation_paused === "yes" ? " w-[55px] h-[55px] rounded-full blur-[1px] grayscale object-cover" : " w-[55px] h-[55px] rounded-full object-cover"}
                                        />
                                        <div
                                            className={`absolute w-4 h-4 rounded-full bottom-0 left-0 
                                            `}
                                            // ${findCategoryColor(item.coach_category_id)}
                                            style={{
                                                backgroundColor: `${findCategoryColor(item.coach_category_id)}`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="pl-3">
                                        <p
                                            className={`text-sm font-medium ${item.is_relation_paused ===
                                                'yes'
                                                ? ' text-gray-400'
                                                : ''
                                                }`}
                                        >
                                            {item.coach_name}
                                        </p>
                                        <p className="text-xs text-gray-500">
                                            {item?.last_message?.message.substring(0, 10)}...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </SimpleBar>

            {userError && <SessionExpired />}
        </aside>
    )
}

export default Left
