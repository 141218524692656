import React from 'react';
import Wrapper from '../../UI/Wrapper'
import Sidebar from '../../components/Common/Sidebar'
import Main from '../../components/FormScreen/Main'
import Left from '../../components/Coach/Welcome/Left'
import Calendar from '../../components/Coach/Welcome/Calendar'

const WelcomeCoach = () => {
  return (
    <Wrapper>
        <div className="flex">
            <Sidebar notLoggedIn={false}/>
        <Main>
          <div className='flex mx-auto space-x-20'>
          <Left/>
          <Calendar />
          </div>
               
            </Main>
        </div>
    </Wrapper>
  )
}

export default WelcomeCoach