import React, { useState } from 'react'

const Left = () => {

    const [next, setNext] = useState(false);
    
    const handleClick = () => {
        setNext(true);
    }

    return (

        <div className="font-constantia w-1/2 xs:w-full md:w-full sm:w-full flex justify-center items-center">

            <div className="w-9/12 h-[30vh] xs:h-[45vh]">
                {!next &&
                    <>
                        <div className="relative flex justify-center items-center border-2 w-24 h-24 rounded-full bg-[#FFFFFF] border-[#FFDBB0]">
                            <span className="text-3xl text-gray-500">+</span>
                        </div>
                        <h1 className="text-5xl leading-[55px] xs:leading-[40px] my-3 xs:text-3xl">
                            It’s my pleasure to meet you Vivek Sahu. Welcome to the Future of Therapy.
                        </h1>
                    </>
                }
                {next &&
                    <>
                        <div className="relative flex justify-center items-center border-2 w-24 h-24 rounded-full bg-[#FFFFFF] border-[#FFDBB0]">
                            
                            <span className="text-3xl text-gray-500">+</span>
                        </div>
                        <h1 className="text-5xl leading-[55px] xs:leading-[40px] my-3 xs:text-3xl">
                            Hi Vivek Sahu, Welcome back to Livup.
                        </h1>
                    </>
                }
                <>
                    <button onClick={handleClick} className="bg-[#FFDBB0] flex justify-center xs:w-9/12 w-[300px] mt-5 font-ubuntu items-center text-center h-[40px] rounded-[20px] font-bold text-[#F77F1F]">
                        Continue
                    </button>
                </>
            </div>

        </div>
        
    )
}

export default Left