import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../keys'

export const chatApi = createApi({
    reducerPath: "chatApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getUsersInChat: builder.query({
            query: () => ({
                url: 'common/list-of-users-in-chat/',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
            })
        }),
        openChat: builder.mutation({
            query: (data) => ({
                url: 'common/open-chat/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        sendMessage: builder.mutation({
            query: (data) => ({
                url: 'common/send-message/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        openProfile: builder.mutation({
            query: (data) => ({
                url: 'common/open-profile/',
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
                body: data
            })
        }),
        getSharedMedia: builder.query({
            query: ({ userId, pageNo }) => ({
                url: `common/shared-media/?user=${userId}&page_no=${pageNo}`,
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
            })
        })
    })
})

export const { useGetUsersInChatQuery, useOpenChatMutation, useSendMessageMutation, useOpenProfileMutation, useGetSharedMediaQuery } = chatApi

