import React from 'react'

const Right = () => {
  return (
    <div className="relative xs:hidden sm:hidden md:hidden lg:hidden w-[37%]">
        <div className="absolute w-[150px] bg-[#FDE9D8] h-[50vh] rounded-b-[80px]"></div>
        <div className="absolute z-10 left-[75px] top-[-20px] w-[150px] bg-[#F77611] h-[40vh] rounded-b-[80px]"></div>
        <div className="absolute z-10 left-[240px] top-[-20px] w-[150px] bg-[#38DED6] h-[30vh] rounded-b-[80px]"></div>
        <div className="absolute left-[300px] top-[50px] w-[150px] bg-[#DDF4ED] h-[55vh] rounded-[80px]"></div>
        <div className="absolute z-10 left-[75px] top-[40vh] w-[150px] bg-[#FFCC33] h-[35vh] rounded-[80px]"></div>
        <div className="absolute z-10 left-[240px] top-[30vh] w-[150px] bg-[#04C362] h-[37vh] rounded-[80px]"></div>
        <div className="absolute z-0 left-[160px] top-[20vh] w-[150px] bg-[#FEFBDF] h-[60vh] rounded-[80px]"></div>
    </div>
  )
}

export default Right