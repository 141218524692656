import React from 'react'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { mediaModalActions } from '../../../../services/mediaModalSlice';
import CloseButton from '../../../../UI/CloseButton'
import CarouselModal from './Modals/CarouselModal';
import MediaModal from './Modals/MediaModal';
import emptyavatar from '../../../../assets/images/emptyavatar.webp'
import LGBTG from '../../../../assets/images/lgbtq-icon.png'
import { BASE_URL } from '../../../../keys';

const ProfileOpenModal = ({ closeModal, currentUser }) => {
    const currentModal = useSelector(state => state.mediaModalReducer.currentModal);
    const dispatch = useDispatch();
    const [initialSlide, setInitialSlide] = useState(1);
    const [pageNo, setPageNo] = useState(1)

    // console.log('currentUser.profile_pic', currentUser.profile_pic);
    return (
        <div className="bg-[#4040407c] font-ubuntu  fixed flex justify-center items-center w-screen h-screen top-0 left-0 z-50">
            {currentModal === 'profileOpen' && <div className="bg-white overflow-hidden rounded-3xl relative w-1/4">
                <div className="bg-heavy p-2 w-full flex justify-center items-center text-center font-[500] text-[1rem] capitalize">
                    {currentUser.user_type} Information
                </div>
                <CloseButton onClick={closeModal} className="absolute top-2 right-3 cursor-pointer text-gray-500" />
                <div className="w-full justify-center p-4 items-center text-center flex flex-col">
                    <div className="w-32 h-32 bg-heavy rounded-full">
                        <img
                            src={`${currentUser.profile_pic}` != 0 ?
                                `${BASE_URL}${currentUser.profile_pic}` :
                                `${emptyavatar}`}
                            alt="profile image"
                            className="w-32 h-32 rounded-full object-cover"
                        />
                    </div>

                    <div className="my-2 font-bold text-lg">
                        {currentUser.name}
                    </div>


                    {(currentUser.user_type === 'client')
                        ?
                        <>
                            <div className="text-center text-xs leading-8">
                                {currentUser.gender}<br />
                                Born on {moment(currentUser.dob).format('DD/MM/YYYY')}<br />
                                Joined on {moment(currentUser.date_joined).format('DD/MM/YYYY')}<br />
                                Works at {currentUser.company_name}
                            </div>
                        </>
                        :
                        <>
                            <div className="text-center text-sm leading-8">
                                {currentUser.description}
                            </div>
                        </>
                    }


                    {currentUser.specializations &&
                        <div>
                            <hr className="my-4 border-t border-gray-300 w-full" />
                            <div className="my-2 font-bold text-base">
                                Specifications
                            </div>
                            <div className="flex flex-wrap justify-center gap-x-3 text-sm">
                                {currentUser.specializations.map((item, i) => (
                                    <p key={i}>{item}</p>
                                ))}
                            </div>
                        </div>
                    }

                    {(currentUser.languages.length > 0) &&
                        <div>
                            <hr className="my-4 border-t border-gray-300 w-full" />
                            <div className="my-2 font-bold text-base">
                                Languages
                            </div>
                            <div className="flex flex-wrap justify-center gap-y-1 gap-x-3 text-sm">
                                {currentUser.languages.map((item, i) => (
                                    <p key={i} className={`${currentUser.languages.length > 1 && "border-r-2 border-black"} pr-3`}>{item.name}</p>
                                ))}

                            </div>
                        </div>
                    }

                    {currentUser.lgbtq === 'y' &&
                        <div>
                            <img
                                src={LGBTG}
                                className="w-[50px] bottom-[90px] mt-5"
                                alt="lgbtq icon"
                            />
                        </div>
                    }

                    <hr className="my-3 border-t border-orange w-full" />
                    <div
                        onClick={() => {
                            dispatch(mediaModalActions.toggleModal("SharedMedia"))
                        }}
                        className="font-bold text-base cursor-pointer text-orange">
                        View shared media
                    </div>
                </div>
            </div>
            }


            {currentModal === "SharedMedia" && <MediaModal pageNo={pageNo} setPageNo={setPageNo} initialSlide={initialSlide} setInitialSlide={setInitialSlide} />}
            {currentModal === "carouselModal" && <CarouselModal pageNo={pageNo} setPageNo={setPageNo} initialSlide={initialSlide} setInitialSlide={setInitialSlide} />}
        </div>
    )
}

export default ProfileOpenModal