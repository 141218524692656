import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URL } from "../keys"

export const categoryApi = createApi({
    reducerPath: "categories",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getCategories: builder.query({ // builder.query for GET request, builder.mutation for any other request
            query: () => ({
                url: 'client/list-categories/',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
            })
        }),
        getLanguages: builder.query({ // builder.query for GET request, builder.mutation for any other request
            query: () => ({
                url: 'client/list-languages/',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('livtoken')
                },
            })
        })
    })
})

export const { useGetCategoriesQuery, useGetLanguagesQuery } = categoryApi