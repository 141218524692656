import React, { useRef, useState } from 'react'
import Input from '../Login/Input'
import verifailed from '../../assets/images/verfailed.png'
import { useLoginMutation, useVerifyOtpMutation } from '../../services/authApi'
import loader from '../../assets/images/loader.gif'
import Countdown from './Countdown'
import { OTP_TIMER } from '../../keys'

const Left = () => {
    const [counter, setCounter] = useState(localStorage.getItem('OTP_TIMER'))

    const [login, { isLoading: loginLoading, error }] = useLoginMutation()
    const [message, setMessage] = useState('')

    const [correct, setCorrect] = useState(true)
    const [verified, setVerified] = useState(false)
    const [email, setEmail] = useState(localStorage.getItem('email'))
    const [verifyOtp, { isLoading }] = useVerifyOtpMutation()
    const form = useRef(null)
    const handleClick = async (e) => {
        e.preventDefault()
        const formData = new FormData(form.current)
        // console.log(formData)
        const response = await verifyOtp(formData)
        const data = await response.data
        // console.log(data)
        try {
            if (data.code === 'Success') {
                localStorage.setItem('livtoken', data.data)
                setVerified(true)
                setCorrect(true)
                setMessage('')
                window.location.replace('/otpverified')
            }
        } catch (error) {
            setCorrect(false)
            setMessage('')
        }
    }

    const resendBtnHandler = async () => {
        setCorrect(true)
        const emailId = localStorage.getItem('email')
        const formData = new FormData()
        formData.append('email', emailId)
        const response = await login(formData)

        localStorage.setItem('OTP_TIMER', OTP_TIMER)
        setCounter(localStorage.getItem('OTP_TIMER'))
        try {
            if (response?.data?.code === 'Success') {
                if (
                    response?.data?.message === 'Otp sent successfully, Please verify it to login!'
                )
                    setMessage('Otp sent successfully!')
                setTimeout(() => {
                    setMessage('')
                }, 2000)
            } else if (response?.error?.status == 400) {
                setMessage(response?.error?.data?.message)
            }
        } catch (error) {
            setMessage(error)
        }
    }

    return (
        <div className="font-constantia w-1/2 xs:w-full md:w-full sm:w-full flex justify-center items-center">
            <div className="w-9/12 h-[30vh]">
                <h1 className="text-5xl leading-[55px] xs:leading-[40px] xs:text-3xl mb-0">
                    Enter OTP code sent to your mail.
                </h1>
                <p className="mt-4 flex items-center gap-2 font-ubuntu text-sm text-gray">
                    {/* <img src={verifailed} className="h-4 w-4" alt="" /> */}
                    OTP code sent to you mail is valid for 180 seconds only.
                </p>
                {!correct && (
                    <p className="mt-16 flex items-center gap-2 xs:mt-8 font-ubuntu text-sm text-[#F77F1F]">
                        <img src={verifailed} className="h-4 w-4" alt="" />{' '}
                        There was problem in verifying your OTP. Please try
                        again
                    </p>
                )}
                <form ref={form} onSubmit={handleClick}>
                    <div
                        className={`relative ${
                            !correct ? 'mt-2' : 'mt-16 xs:mt-8'
                        } mb-4 h-10`}
                    >
                        <Input
                            type="text"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full hidden font-ubuntu text-xs rounded-[30px] !p-4 focus:outline-none h-[40px]"
                        />
                        <div className="relative">
                            <Input
                                placeholder={'OTP'}
                                type="text"
                                name="otp"
                                className="w-full font-ubuntu text-xs rounded-[30px] !p-4 focus:outline-none h-[40px]"
                                maxLength={10}
                            />

                            <div
                                className="absolute font-ubuntu text-xs font-bold text-[#F77F1F] right-[10px] top-[5px] h-8 flex justify-center items-center text-center w-[100px] bg-white"
                                onClick={() => resendBtnHandler()}
                            >
                                <button
                                    className={`cursor-pointer mr-1`}
                                    disabled={counter > 0 ? true : false}
                                    type="button"
                                >
                                    {loginLoading ? (
                                        <img
                                            className="w-8 h-8"
                                            src={loader}
                                            alt=""
                                        />
                                    ) : (
                                        'Resend '
                                    )}
                                </button>
                                {counter > 0 && (
                                    <Countdown
                                        counter={counter}
                                        setCounter={setCounter}
                                    />
                                )}
                            </div>
                        </div>
                        <span className="absolute bottom-[-25px] right-0">
                            {message}
                        </span>
                    </div>

                    <button
                        type="submit"
                        disabled={isLoading}
                        className="bg-[#FFDBB0] flex justify-center xs:w-9/12 w-[300px] font-ubuntu items-center text-center h-[40px] rounded-[20px] font-bold text-[#F77F1F]" //mt-6
                    >
                        {isLoading ? (
                            <img className="w-8 h-8" src={loader} alt="" />
                        ) : (
                            'Continue'
                        )}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Left
