import React from 'react'
import { useEffect } from 'react';
import verifieds from '../../assets/images/verified.svg'
import { useGetCurrentUserQuery } from '../../services/authApi'

const Left = () => {

    const { data: user } = useGetCurrentUserQuery();

    // console.log(user);

    useEffect(() => {
        if(user?.data?.user_type === 'client'){
            setTimeout(() => {
                window.location.replace("/welcome");
            }, 2000);
            
        } else if (user?.data?.user_type === 'coach'){
            setTimeout(() => {
                window.location.replace("/coach-welcome");
            }, 2000);
            
        }

    }, [user])

    return (
        <div className="font-constantia w-1/2 xs:w-full md:w-full sm:w-full flex justify-center items-center">

            <div className="w-9/12 h-[30vh]">

                <img src={verifieds} className="w-16 mb-4 h-16" alt="" />
                <h1 className="text-5xl leading-[55px] xs:leading-[40px] xs:text-3xl">
                    OTP verification successful
                </h1>

            </div>

        </div>
    )
}

export default Left