import React from 'react'
import $ from 'jquery';
import logo from '../../assets/images/logo-livup.svg'
import { Link } from 'react-router-dom'

export default function NavbarDummy() {

    const openNav = () => {
        console.log("hii Open Navbar");
        $(this).toggleClass('open');
        $('body').toggleClass('menu-open');
        $('#nav-icon1').toggleClass('open !mr-0');
    }

    return (
        <>
            <a href="javascript:void(0);" id="scroll-to-top-ico">
                {/* <img src="images/infinity_gray.png" align="Infinity Logo"> */}
            </a>

            {/* <!-- Menu --> */}
            <div class="menu-icon-container absolute right-[25px] top-[25px] z-[999]">
                {/* <!-- <em class="menu-label">MENU</em> --> */}
                <div id="nav-icon1" className='mt-4 mr-24 w-[32px] h-[38px] relative cursor-pointer' onClick={openNav}>
                    <span className='block absolute h-[2px] w-full bg-white rounded-lg opacity-100 left-0'></span>
                    <span className='block absolute h-[2px] w-full bg-white rounded-lg opacity-100 left-0'></span>
                    <span className='block absolute h-[2px] w-full bg-white rounded-lg opacity-100 left-0'></span>
                </div>
            </div>
            <div class="menu-container z-[100] fixed flex items-stretch overflow-hidden h-screen w-full pointer-events-none top-0 left-0 bottom-0 right-0 ">
                <div class="menu-sliders flex justify-center items-center"></div>
                <div class="menu-sliders flex justify-center items-center"></div>
                <div class="menu-sliders flex justify-center items-center"></div>
                <div class="menu flex justify-center items-center fixed h-screen w-full z-[3]">
                    <ul className='pl-0 pb-0'>
                        <li>
                            <a href="#home" class="logo-wrap absolute top-[1rem] left-[25px]">
                                {/* <img src={logo} alt="Logo"/> */}
                                <div className='flex flex-col' data-aos='fade-up'>
                                    <Link to="/home" className='inline-block'>
                                        <img src={logo} alt="Logo" className='inline-block w-[55px]' />
                                        <span className='font-constantia text-[#fff] text-[40px] relative  bottom-[-8px] left-1'>Hapsmiths</span>
                                    </Link>
                                    <span className='mt-[-1rem] font-ubuntu text-[15px] text-[#FFDBB0] font-thin inline-block relative left-[60px] bottom-[-8px]'>Your Department Of Happiness</span>
                                </div>
                            </a>
                        </li>
                        <li className='font-thin text-[45px] text-white min-h-[45px] p-2.5 list-none text-left'><a className='cursor-pointer font-black text-white py-2 px-0 hover:no-underline' href="#home">Home</a></li>
                        <li className='font-thin text-[45px] text-white min-h-[45px] p-2.5 list-none text-left'><a className='cursor-pointer font-black text-white py-2 px-0 hover:no-underline' href="#portfolio">About Us</a></li>
                        <li className='font-thin text-[45px] text-white min-h-[45px] p-2.5 list-none text-left'><a className='cursor-pointer font-black text-white py-2 px-0 hover:no-underline' href="#about">Testimonials</a></li>
                        <li className='font-thin text-[45px] text-white min-h-[45px] p-2.5 list-none text-left'><a className='cursor-pointer font-black text-white py-2 px-0 hover:no-underline' href="#contact">Contact Us</a></li>
                        <li className='font-thin text-[45px] text-white min-h-[45px] p-2.5 list-none text-left'>
                            {/* <a href="https://www.instagram.com/createstudio.in/" target="blank">
                                Follow us on <span></span> */}
                            {/* </a> */}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
