import React, { useState, useRef, useCallback } from "react"
import Backdrop from "../../../../UI/Backdrop"
import ModalWrapper from "./ModalWrapper"
import camera from '../../../../assets/images/camera.png'
import gallery from '../../../../assets/images/gallery.png'
import closeIcon from "../../../../assets/images/close x.svg"
import { useUpdateProfileMutation } from "../../../../services/authApi"
import loader from '../../../../assets/images/loader.gif'
import Webcam from "react-webcam";
import dataURLtoFile from "../../../../utilities/fileConvertAlgorithm"

import { FileUploader } from "react-drag-drop-files"

// import '../../../../../node_modules/antd/dist/reset.css';
import { Upload } from "antd"
import ImgCrop from "antd-img-crop"


// const fileTypes = ["JPG", "PNG"];

const videoConstraints = {
	width: 640,
	height: 640,
	facingMode: "user"
};


const getSrcFromFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.originFileObj);
		reader.onload = () => resolve(reader.result);
	});
};

const ProfilePictureModal = (props) => {



	// const [file, setFile] = useState(null);

	// const [crop, setCrop] = useState({ x: 0, y: 0 })
	// const [zoom, setZoom] = useState(1)
	// const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
	// 	console.log(croppedArea, croppedAreaPixels)
	// }, [])





	const [imageFile, setImageFile] = useState(null);
	const [imageURL, setImageURL] = useState(null);
	const hiddenFileInput = useRef(null);
	const [cameraOpen, setCameraOpen] = useState(false);
	const [updateProfile, { isLoading, isError }] = useUpdateProfileMutation();
	const handleFileButton = (e) => {
		e.preventDefault();
		hiddenFileInput.current.click();
	};

	// const handleChange = (e) => {
	// 	console.log('e....................', e);

	// 	setImageFile(e);
	// 	var reader = new FileReader();
	// 	console.log('reader', reader);
	// 	//eslint-disable-next-line
	// 	var url = reader.readAsDataURL(e);

	// 	reader.onloadend = function (e) {
	// 		setImageURL(...[reader.result])
	// 		//eslint-disable-next-line
	// 	}.bind(this);
	// };

	// console.log('imageFile', imageFile)
	// console.log('imageURL', imageURL)

	const addImage = (e) => {
		// console.log('e---------', e);
		// console.log('e.target.files[0]-----------', e.target.files[0]);
		setImageFile(e.target.files[0]);
		const file = e.target.files[0];
		var reader = new FileReader();
		//eslint-disable-next-line
		var url = reader.readAsDataURL(file);

		reader.onloadend = function (e) {
			setImageURL(...[reader.result])
			//eslint-disable-next-line
		}.bind(this);
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (imageFile !== null) {
			// const blob = await fetch(imageFile).then((res) => res.blob());
			const formData = new FormData();
			//  console.log(blob);

			formData.append('name', props.nameField);
			formData.append('gender', props.genderField);
			formData.append('dob', props.dobField);
			formData.append('profile_pic', imageFile);
			// console.log('formData', formData)

			const response = await updateProfile(formData);
			// console.log('response', response)
			if (response.data) {
				window.location.reload();
			}
		}

	}

	const [fileList, setFileList] = useState([]);


	const changeImage = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		// console.log('newFileList', newFileList[0]?.originFileObj);
		setImageURL(URL.createObjectURL(newFileList[0]?.originFileObj))
		setImageFile(newFileList[0]?.originFileObj)
	};

	// const onPreview = async (file) => {
	// 	const src = file.url || (await getSrcFromFile(file));
	// 	const imgWindow = window.open(src);

	// 	if (imgWindow) {
	// 		const image = new Image();
	// 		image.src = src;
	// 		imgWindow.document.write(image.outerHTML);
	// 	} else {
	// 		window.location.href = src;
	// 	}
	// };


	return (
		<div>
			<Backdrop />
			{/* <ModalWrapper
				title="Profile Picture"
				onCloseProfileModal={props.onCloseProfileModal}
			> */}
			<div
				className={
					"absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[999] min-w-[450px] min-h-[200px] bg-white rounded-[15px] "
				}
			>
				<div className="bg-[#FDF5EE] p-2 text-center rounded-t-[15px] font-ubuntu font-bold text-[16px] relative">
					Profile Picture
					<span
						className="absolute w-[20px] h-[20px] object-contain top-2.5 right-3 cursor-pointer"
						onClick={() => {
							props.onCloseProfileModal()
						}}
					>
						<img
							src={closeIcon}
							alt="close icon"
							className="h-[22px] w-[17.27px] object-contain"
						/>
					</span>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="w-[88%] mx-auto mt-5 mb-10">
						<div className="flex flex-col justify-between items-center relative">
							<div className="flex justify-center my-4 w-full">
								{imageURL ?
									<img src={imageURL} alt="" className="w-24 h-24 rounded-full" />
									:
									<div className="relative flex justify-center items-center border-2 w-24 h-24 rounded-full bg-[#FDF5EE] border-[#FFDBB0]">
										<span className="text-3xl text-gray-500">+</span>
									</div>
								}
							</div>


							{/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}

							<div className="flex justify-center">
								<ImgCrop shape="round">
									<Upload
										action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
										listType="picture-card"
										fileList={fileList}
										onChange={changeImage}
										// onPreview={onPreview}
										showUploadList={false}
									>
										{fileList.length < 2 && 'Crop & Upload'}
									</Upload>
								</ImgCrop>
							</div>


							{/* <p className="m-auto text-center w-full text-sm text-gray-500">Drag photo here</p> */}
							<p className="m-auto text-center w-full text-sm text-gray-500">or</p>
							<br />
							<div className="flex border-[#FFDBB0] border-2 m-auto rounded-[15px] w-8/12 justify-between p-3">
								<div className="w-[100px]">
									<div onClick={() => setCameraOpen(true)} className="bg-light w-[60px] p-3 m-auto rounded-[5px]">
										<img src={camera} alt="" className="w-6 h-6 m-auto cursor-pointer" />
									</div>

									<p className="m-auto text-center w-full text-sm text-gray-500">Use camera</p>
								</div>
								<div className="w-[100px]">
									<div className="bg-[#FDF5EE] w-[60px] p-3 m-auto rounded-[5px]">
										<input
											accept="image/*"
											type="file" style={{ display: "none" }} ref={hiddenFileInput}
											onChange={addImage} name="profile_pic" />
										<img src={gallery} alt="" className="w-6 h-6 m-auto cursor-pointer" onClick={handleFileButton} />
									</div>
									<p className="m-auto text-center w-full text-sm text-gray-500">Upload photo</p>
								</div>
							</div>
						</div>

						{isError &&
							<div className="my-2 text-lightOrange text-sm">Something went wrong! Please try again or refresh the page.</div>
						}
						<div className="flex justify-center items-center space-x-5">
							<button
								type="submit"
								disabled={isLoading}
								className="bg-[#FFDBB0] text-[#F77611] font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
							>
								{isLoading ? <img src={loader} className="w-8 h-8 m-auto" alt="loading" />
									: "Save"
								}

							</button>
						</div>


						{cameraOpen &&
							<Webcam
								audio={false}
								mirrored
								screenshotFormat="image/jpeg"
								videoConstraints={videoConstraints}
								className="absolute w-[97%] h-[97%] rounded-3xl top-0 left-0 right-0 bottom-0 m-auto"
							>
								{({ getScreenshot }) => (
									<>
										<div
											className="bg-heavy hover:bg-gray-800 cursor-pointer z-[9999] w-[60px] h-[60px] flex items-center justify-center absolute bottom-10 left-[44%] p-3 rounded-full"
											onClick={(e) => {
												const imageSrc = getScreenshot();
												// console.log('imageSrc', imageSrc);
												setImageURL(imageSrc);


												setImageFile(dataURLtoFile(imageSrc, 'file01.jpeg'));

												setCameraOpen(false);
											}}
										>
											<img src={camera} alt="" className="w-6 h-6 m-auto " />
										</div>
										<div
											onClick={() => setCameraOpen(false)}
											className="bg-heavy hover:bg-gray-800 cursor-pointer z-[9999] w-max flex items-center justify-center absolute top-4 right-4 text-lightOrange font-bold font-ubuntu rounded-3xl px-3 py-1 ">
											Go back
										</div>
									</>
								)}
							</Webcam>
						}

						{/* <img src={testURL} className="w-10 h-10" alt="" /> */}

					</div>
				</form>
			</div>
		</div>
	)
}

export default ProfilePictureModal
