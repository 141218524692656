import React, { useState, useRef } from 'react'
import Input from '../../../Login/Input'
import Button from '../../../../UI/Button'
import { useNavigate } from 'react-router-dom';
import ModalWrapper from '../../../../UI/ModalWrapper'
import { useReportClientMutation } from '../../../../services/coachScreenApi';
import { ToastContainer, toast } from 'react-toastify'
import api from '../../../../services/api';
import { useEffect } from 'react';


function VideoJoinModal({ onCloseModal, videoLink, currentChatUser, feedbackModal }) {

    const form = useRef(null)
    const navigate = useNavigate();
    const [vLink, setvLink] = useState(null)
    const [loading, setloading] = useState(false)

    const handleReportUser = async (e) => {
        e.preventDefault();

    }

    useEffect(() => {
        setvLink(videoLink)
    }, [videoLink])

    const createNewVideo = () => {
        setloading(true)
        const formData = new FormData();
        formData.append("booking_id", currentChatUser?.last_message.booking_id)
        formData.append("client_id", currentChatUser?.user_id)

        api.post(`employee/generate-meeting-link`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("livtoken")}`,
            }
        }).then((res) => {
            console.log(res.data.data, "RES*** in meeting again")
            setvLink(res.data.data)
            setloading(false)

        }).catch((err) => {
            toast.warn(`${err}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }

    const joinButton = () => {
        window.open(vLink)
        onCloseModal()
        feedbackModal()
    }

    return (
        <ModalWrapper>
            <ToastContainer />
            {
                vLink ? <div className="bg-white rounded-3xl text-xl text-center font-ubuntu px-12 py-12 w-6/12">
                    <span className="font-semibold">
                        Start the session?
                    </span>
                    <form ref={form} onSubmit={handleReportUser} >
                        <div className="flex gap-12 w-full mt-8 justify-center">
                            <a onClick={joinButton} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium cursor-pointer rounded-3xl">Join</a>
                            {/* <Button onClick={() => {
                                navigator.clipboard.writeText(vLink ? vLink : "");
                                toast.warn(`Link copied to clipboard`, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Copy Link</Button> */}
                            <Button onClick={onCloseModal} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Close</Button>
                        </div>
                    </form>
                </div> :
                    <div className="bg-white rounded-3xl text-xl text-center font-ubuntu px-12 py-12 w-3/12">
                        <span className="font-semibold">
                            create a new link?
                        </span>
                        <form ref={form} onSubmit={handleReportUser} >
                            {
                                loading ? <div className="flex flex-row justify-center">
                                    <p className="text-sm">Loading...</p>
                                </div> : <p onClick={() => {
                                    navigator.clipboard.writeText(vLink ? vLink : "");
                                    toast.warn(`Link copied to clipboard`, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }} className="text-sm cursor-pointer">{vLink ? vLink : null}</p>
                            }
                            <div className="flex gap-12 w-full mt-8  justify-center">
                                <Button onClick={createNewVideo} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Create</Button>
                                <Button onClick={onCloseModal} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Close</Button>
                            </div>
                        </form>
                    </div>
            }
        </ModalWrapper>
    )
}

export default VideoJoinModal