import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { menu } from './menuItems'
import { settingsActions } from '../../services/settingsSlice'


const Menu = () => {

    const currentOption = useSelector((state) => state.settingsReducer.currentOption);
    // console.log(currentOption)
    const dispatch = useDispatch();
    
    
    return (
        <aside className="w-[30%] flex flex-col justify-center">

            <h1 className="text-2xl mb-10 font-constantia font-bold">Settings</h1>
            {menu.map((item, i) => (
                <div onClick={() => { dispatch(settingsActions.changeOption(item.text)) }} key={i} 
                className={`w-full ${(i !== 0) ? "border-b-[1px]" : "border-y-[1px]"} cursor-pointer py-2 border-orange `}>
                    <div className={`w-full ${currentOption === item.text ? "bg-white" : ""} rounded-[20px] flex items-center h-[50px] p-2 gap-10`}>
                        <img className="w-8 h-8" src={item.img} alt={item.text} />
                        <span className=" text-xl font-bold font-ubuntu">{item.text}</span>
                    </div>
                </div>
            ))}

        </aside>
    )
}

export default Menu