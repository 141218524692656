import React, { useEffect, useRef, useState } from 'react'
import { useLoginMutation } from '../../services/authApi';
import { Link, useNavigate } from 'react-router-dom';
import Input from './Input'
import loader from '../../assets/images/loader.gif'
import { OTP_TIMER } from '../../keys';

const Left = () => {

    const [login, { isLoading, isError, error }] = useLoginMutation();
    // console.log("ERR",error)
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const form = useRef(null)
    const [iAgree, setIAgree] = useState("")


    const handleLogin = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        // console.log(formData)
        const response = await login(formData);
        console.log("REDDDDDDDDDDDDDDD", response)
        //~ this formData param is what you gave in query method (RTK Query)
        const data = await response.data;
        try {
            if (response?.data?.code === 'Success') {
                setMessage(data.message);
                localStorage.setItem('email', email);
                localStorage.setItem('OTP_TIMER', OTP_TIMER)//vk
                navigate("/otp");
            } else if (response?.error?.status == 400) {
                response?.error?.data?.message == "Something went wrong" ? setMessage(response?.error?.data?.data?.email[0]) : response?.error?.data?.message == "Something went wrong while inserting user." ? setMessage(response?.error?.data?.message) : response?.error?.data?.message == "User is blocked." ? setMessage("User is blocked.") : setMessage("please try after sometime.")
            } else if (response?.error?.originalStatus == 500) {
                setMessage("Invalid email id. Please provide a valid one!")
            }
        } catch (error) {
            setMessage("Some error occured! Please try again.");
        }


    }

    const saveIAgree = () => {
        let IAgreeCheckbox = document.getElementById("iAgree");
        localStorage.setItem("TnCP", IAgreeCheckbox.checked);
        setIAgree(IAgreeCheckbox.checked)
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("TnCP")) === true) {
            document.getElementById("iAgree").checked = true;
            setIAgree(true)
        } else {
            console.log("No")
            setIAgree(false)
        }
    }, [iAgree])


    return (
        <div className="font-constantia w-1/2 xs:w-full md:w-full sm:w-full flex flex-col justify-center items-center">

            <div className="w-9/12 xs:w-11/12 h-[70vh] xs:h-[40vh] sm:h-[50vh] md:h-[45vh]">

                <h1 className="lp-lg:text-[2.5rem] lp-xl:text-5xl xs:hidden sm:hidden md:hidden lp-lg:leading-[45px] lp-xl:leading-[60px] xs:leading-[40px] xs:text-3xl">
                    Unlimited access to Mental Health Therapists, Nutritionists,<br /> Fitness and Habit coaches
                </h1>

                <h1 className="text-5xl  lg:hidden xl:hidden 2xl:hidden leading-[55px] xs:leading-[40px] xs:text-3xl">
                    Welcome to <b>Hapsmiths</b>, <br /> Let's get started!
                </h1>

                <form ref={form} onSubmit={handleLogin} >
                    <Input name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={"Enter email id"} type="email" className="w-full font-ubuntu placeholder:text-xs rounded-[30px] !p-4 mt-16 xs:mt-8 mb-4 focus:outline-none h-[40px]" />

                    <div className='ml-2 mb-2 font-ubuntu text-sm text-gray-500'>
                        <span>By continuing, you freely consent and agree to Hapsmiths <Link to="/terms-conditions-privacy" className='underline' target="_blank">Terms and Conditions and Privacy Policy.</Link></span>
                        <label htmlFor="iAgree" className='block cursor-pointer mt-2' onClick={saveIAgree}>
                            <input name="i_agree" type="checkbox" id='iAgree' className='cursor-pointer mt-2' /> I Agree
                        </label>
                    </div>

                    <button type="submit" disabled={isLoading || !iAgree} className="bg-[#FFDBB0] flex justify-center xs:w-full w-[300px] font-ubuntu items-center text-center h-[40px] rounded-[20px] font-bold text-[#F77F1F]">
                        {!isLoading && <>Login</>} {isLoading && <img src={loader} className="w-8 h-8" alt="" />}
                    </button>
                </form>

                {message && <span>{message}</span>}

            </div>

        </div>
    )
}

export default Left