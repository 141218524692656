import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import notes from '../../../assets/images/notes.png'
import { useGetCurrentUserQuery } from '../../../services/authApi'
import { useGetDashboardDetailQuery } from '../../../services/coachScreenApi'

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { data } from 'autoprefixer'
// import { BASE_URL } from "../../../keys"
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import api from '../../../services/api'
import { BASE_URL, currentTime} from '../../../keys'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const momentDateFormat = (dateVal) => moment(dateVal).format('YYYY-MM-DD')
const momentTimeFormat = (timeVal) =>
    moment(timeVal, 'h:mm:ss A').format('HH:mm')

const Left = () => {

    const [arrow, setArrow] = useState('down')
    const [height, setHeight] = useState('h-8')
    const [daySelected, setDaySelected] = useState('Today')
    const [todayWidth, setTodayWidth] = useState('w-[120px]')
    const navigate = useNavigate()

    const {
        data: userInfo,
        isLoading,
        isError,
        isSuccess,
    } = useGetCurrentUserQuery()
    const user = userInfo?.data?.name

    // const { data: dashBoardInfo, isLoading: dashboardLoading } = useGetDashboardDetailQuery()
    const [dashBoardInfo, setDashBoardInfo] = useState([])
    // console.log('Dashboard Details', dashBoardInfo)

    useEffect(() => {
        api.get(`employee/dashboard-detail`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("livtoken")}`,
            }
        }).then((res) => {
            setDashBoardInfo(res.data)
        })
    }, [])


    //To determine whether it is Morning, Noon or Evening
    const greetings =
        new Date().getHours() < 12
            ? 'Morning'
            : new Date().getHours() >= 12 && new Date().getHours() < 17
                ? 'Afternoon'
                : 'Evening'

    //To determine Mr./ Ms. based on gender info
    // const gender = userInfo?.data?.gender
    // const prefix = gender === 'Male' ? 'Mr.' : 'Ms.'

    const handleHeightClick = () => {
        if (height === 'h-8') {
            setHeight('')
            setArrow('up')
            setTodayWidth('w-[220px]')
        } else {
            setHeight('h-8')
            setArrow('down')
            setTodayWidth('w-[120px]')
        }
    }

    const getClientNotes = (client_id) => {
        navigate(`/coach-notes/${client_id}`)
    }

    return (
        <aside className="bg-white rounded-2xl xl:w-[400px] 2xl:w-[600px] p-4 h-[92%] mt-8  flex justify-between flex-col">
            <div>
                <h1 className="w-full mt-6 font-bold text-center text-3xl font-constantia">
                    Good {greetings}, <br />
                    {userInfo?.data?.name}!
                </h1>
                <p className="font-ubuntu w-full text-center mt-3 text-sm">
                    Catch up from where you left off, kindly check out <br />{' '}
                    your upcoming sessions.
                </p>
            </div>

            <div className="relative">
                <div className="relative flex text-sm mt-16 text-gray-500 border-b-[2px] pb-3 mb-4 border-b-orange-200 font-ubuntu justify-between w-full">
                    <p className="flex gap-2 pb-0 items-center text-base">
                        Client List
                    </p>

                    <div
                        className={`absolute z-10 left-[110px] top-[-5px] ${height} overflow-hidden cursor-pointer bg-[#FDF5EE] ${todayWidth} rounded-[20px] border-[#FFDBB0] border text-sm text-gray-500`}
                    >
                        <div
                            onClick={handleHeightClick}
                            className="w-[100px] h-8 py-1 px-3"
                        >
                            <p className="">
                                {' '}
                                {daySelected ? daySelected : 'Today'}{' '}
                            </p>
                            {arrow === 'down' && (
                                <span className="absolute w-2 h-2 top-1 right-4 text-[#F76411]">
                                    &#9660;
                                </span>
                            )}
                            {arrow === 'up' && (
                                <span className="absolute w-2 h-2 top-1 right-4 text-[#F76411]">
                                    &#9650;
                                </span>
                            )}
                        </div>

                        <div className="bg-white p-4">
                            <div className="flex">
                                <div
                                    onClick={() => {
                                        setDaySelected('Yesterday')
                                        setHeight('h-8')
                                        setArrow('down')
                                        setTodayWidth('w-[120px]')
                                    }}
                                    className={`${daySelected === 'Yesterday'
                                        ? 'bg-[#F76411] text-white'
                                        : 'bg-[#FDF5EE]'
                                        }  border-[#FFDBB0] w-[100px] border-2 p-1 flex justify-center items-center h-8 rounded-[20px] text-center text-xs`}
                                >
                                    Yesterday
                                </div>
                                <div
                                    onClick={() => {
                                        setDaySelected('Today')
                                        setHeight('h-8')
                                        setArrow('down')
                                        setTodayWidth('w-[120px]')
                                    }}
                                    className={`${daySelected === 'Today'
                                        ? 'bg-[#F76411] text-white'
                                        : 'bg-[#FDF5EE]'
                                        }  border-[#FFDBB0] w-[100px] border-2 p-1 flex justify-center items-center h-8 rounded-[20px] mx-2 text-center text-xs`}
                                >
                                    Today
                                </div>
                                <div
                                    onClick={() => {
                                        setDaySelected('Tomorrow')
                                        setHeight('h-8')
                                        setArrow('down')
                                        setTodayWidth('w-[120px]')
                                    }}
                                    className={`${daySelected === 'Tomorrow'
                                        ? 'bg-[#F76411] text-white'
                                        : 'bg-[#FDF5EE]'
                                        }  border-[#FFDBB0] w-[100px] border-2 p-1 flex justify-center items-center h-8 rounded-[20px] text-center text-xs`}
                                >
                                    Tomorrow
                                </div>
                            </div>
                            <input
                                type="text"
                                name="daySelected"
                                readOnly
                                value={daySelected}
                                className="hidden"
                            />
                        </div>
                    </div>
                </div>

                <div className="relative w-full h-[190px] flex gap-2 ">
                    {daySelected === 'Today' && (
                        <>
                            {dashBoardInfo?.data?.datas_today.length === 0 && (
                                // <p className="absolute top-[20%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                <p className="absolute top-[20%] text-center w-full">
                                    No upcoming sessions!
                                </p>
                            )}
                            {dashBoardInfo?.data?.datas_today.length >= 4 ? (
                                <Swiper
                                    // install Swiper modules
                                    modules={[
                                        Navigation,
                                        Pagination,
                                        Scrollbar,
                                        A11y,
                                    ]}
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    className="clientList w-full"
                                    navigation
                                    pagination={false}
                                    scrollbar={false}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() =>
                                        console.log('slide change')
                                    }
                                >
                                    {dashBoardInfo?.data?.datas_today.map(
                                        (client, index) => {
                                            return (
                                                <SwiperSlide
                                                    key={index}
                                                    className="!w-[135px]"
                                                >
                                                    <div
                                                        key={index}
                                                        className="w-[135px] h-[150px] bg-[#FDF5EE] rounded-2xl pr-4"
                                                    >
                                                        <div className="flex">
                                                            <div className="relative overflow-hidden flex justify-center items-center border w-[45px] h-[45px] rounded-full bg-[#FFFFFF] border-[#FFDBB0] mt-3 ml-2">
                                                                <img
                                                                    src={`${client.user_profile_pic}` !== '' ?
                                                                        `${BASE_URL}${client.user_profile_pic}` :
                                                                        `${emptyavatar}`}
                                                                    alt="profile image"
                                                                    className="rounded-full w-[45px] h-[45px] object-cover"
                                                                />
                                                            </div>

                                                            <div className="bg-[#FDF5EE] rounded-[5px]">
                                                                <img
                                                                    src={notes}
                                                                    alt=""
                                                                    className="h-8 mt-4 ml-8 cursor-pointer"
                                                                    onClick={() => getClientNotes(client.user_id)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <p className="w-full pl-3 pt-2 text-[14px] font-medium">
                                                            {client.user_name}
                                                        </p>
                                                        <p className="w-full pl-3 pt-2 text-[13px] text-[#868686]">
                                                            {client?.status ===
                                                                'pending'
                                                                ? (momentTimeFormat(currentTime) < momentTimeFormat(client?.session_time)) ? "Upcoming Session": "Did not attend" : "Attended"
                                                            }
                                                        </p>
                                                        <p className="w-full pl-3 text-sm font-medium">
                                                            {client.session_time}
                                                        </p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    )}
                                </Swiper>
                            ) : (
                                dashBoardInfo?.data?.datas_today.map(
                                    (client, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="w-[135px] h-[150px] bg-[#FDF5EE] rounded-2xl pr-4"
                                            >
                                                <div className="flex">
                                                    <div className="relative overflow-hidden flex justify-center items-center border w-[45px] h-[45px] rounded-full bg-[#FFFFFF] border-[#FFDBB0] mt-3 ml-2">
                                                        <img
                                                            src={`${client.user_profile_pic}` !== '' ?
                                                                `${BASE_URL}${client.user_profile_pic}` :
                                                                `${emptyavatar}`}
                                                            alt="profile image"
                                                            className="rounded-full w-[45px] h-[45px] object-cover"
                                                        />
                                                    </div>

                                                    <div className="bg-[#FDF5EE] rounded-[5px]">
                                                        <img
                                                            src={notes}
                                                            alt=""
                                                            className="h-8 mt-4 ml-8 cursor-pointer"
                                                            onClick={() => getClientNotes(client.user_id)}
                                                        />
                                                    </div>
                                                </div>
                                                <p className="w-full pl-3 pt-2 text-[14px] font-medium">
                                                    {client.user_name}
                                                </p>
                                                <p className="w-full pl-3 pt-2 text-[13px] text-[#868686]">
                                                    {client?.status ===
                                                        'pending'
                                                        ? (momentTimeFormat(currentTime) < momentTimeFormat(client?.session_time)) ? "Upcoming Session": "Did not attend" : "Attended"
                                                    }
                                                </p>
                                                <p className="w-full pl-3 text-sm font-medium">
                                                    {client.session_time}
                                                </p>
                                            </div>
                                        )
                                    }
                                )
                            )}
                        </>
                    )}

                    {daySelected === 'Tomorrow' && (
                        <>
                            {dashBoardInfo?.data?.datas_tomorrow.length ===
                                0 && (
                                    // <p className="absolute top-[20%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                    <p className="absolute top-[20%] text-center w-full">
                                        No upcoming sessions!
                                    </p>
                                )}
                            {dashBoardInfo?.data?.datas_tomorrow.length >= 4 ? (
                                <Swiper
                                    // install Swiper modules
                                    modules={[
                                        Navigation,
                                        Pagination,
                                        Scrollbar,
                                        A11y,
                                    ]}
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    className="clientList w-full"
                                    navigation
                                    pagination={false}
                                    scrollbar={false}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() =>
                                        console.log('slide change')
                                    }
                                >
                                    {dashBoardInfo?.data?.datas_tomorrow.map(
                                        (client, index) => {
                                            return (
                                                <SwiperSlide
                                                    key={index}
                                                    className="!w-[135px]"
                                                >
                                                    <div
                                                        key={index}
                                                        className="w-[135px] h-[150px] bg-[#FDF5EE] rounded-2xl pr-4"
                                                    >
                                                        <div className="flex">
                                                            <div className="relative overflow-hidden flex justify-center items-center border w-[45px] h-[45px] rounded-full bg-[#FFFFFF] border-[#FFDBB0] mt-3 ml-2">
                                                                <img
                                                                    src={`${client.user_profile_pic}` !== '' ?
                                                                        `${BASE_URL}${client.user_profile_pic}` :
                                                                        `${emptyavatar}`}
                                                                    alt="profile image"
                                                                    className="rounded-full w-[45px] h-[45px] object-cover"
                                                                />
                                                            </div>

                                                            <div className="bg-[#FDF5EE] rounded-[5px]">
                                                                <img
                                                                    src={notes}
                                                                    alt=""
                                                                    className="h-8 mt-4 ml-8 cursor-pointer"
                                                                    onClick={() => getClientNotes(client.user_id)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <p className="w-full pl-3 pt-2 text-[14px] font-medium">
                                                            {client.user_name}
                                                        </p>
                                                        <p className="w-full pl-3 pt-2 text-[13px] text-[#868686]">
                                                            {client?.status ===
                                                                'pending'
                                                                ? `Upcoming Session`
                                                                : ''}
                                                        </p>
                                                        <p className="w-full pl-3 text-sm font-medium">
                                                            {client.session_time}
                                                        </p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    )}
                                </Swiper>
                            ) : (
                                dashBoardInfo?.data?.datas_tomorrow.map(
                                    (client, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="w-[135px] h-[150px] bg-[#FDF5EE] rounded-2xl pr-4"
                                            >
                                                <div className="flex">
                                                    <div className="relative overflow-hidden flex justify-center items-center border w-[45px] h-[45px] rounded-full bg-[#FFFFFF] border-[#FFDBB0] mt-3 ml-2">
                                                        <img
                                                            src={`${client.user_profile_pic}` !== '' ?
                                                                `${BASE_URL}${client.user_profile_pic}` :
                                                                `${emptyavatar}`}
                                                            alt="profile image"
                                                            className="rounded-full w-[45px] h-[45px] object-cover"
                                                        />
                                                    </div>

                                                    <div className="bg-[#FDF5EE] rounded-[5px]">
                                                        <img
                                                            src={notes}
                                                            alt=""
                                                            className="h-8 mt-4 ml-8 cursor-pointer"
                                                            onClick={() => getClientNotes(client.user_id)}
                                                        />
                                                    </div>
                                                </div>
                                                <p className="w-full pl-3 pt-2 text-[14px] font-medium">
                                                    {client.user_name}
                                                </p>
                                                <p className="w-full pl-3 pt-2 text-[13px] text-[#868686]">
                                                    {client?.status ===
                                                        'pending'
                                                        ? `Upcoming Session`
                                                        : ''}
                                                </p>
                                                <p className="w-full pl-3 text-sm font-medium">
                                                    {client.session_time}
                                                </p>
                                            </div>
                                        )
                                    }
                                )
                            )}
                        </>
                    )}

                    {daySelected === 'Yesterday' && (
                        <>
                            {dashBoardInfo?.data?.datas_yesterday.length ===
                                0 && (
                                    // <p className="absolute top-[20%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                    <p className="absolute top-[20%] text-center w-full">
                                        There were no completed sessions.
                                    </p>
                                )}
                            {dashBoardInfo?.data?.datas_yesterday.length >=
                                4 ? (
                                <Swiper
                                    // install Swiper modules
                                    modules={[
                                        Navigation,
                                        Pagination,
                                        Scrollbar,
                                        A11y,
                                    ]}
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    className="clientList w-full"
                                    navigation
                                    pagination={false}
                                    scrollbar={false}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() =>
                                        console.log('slide change')
                                    }
                                >
                                    {dashBoardInfo?.data?.datas_yesterday.map(
                                        (client, index) => {
                                            return (
                                                <SwiperSlide
                                                    key={index}
                                                    className="!w-[135px]"
                                                >
                                                    <div
                                                        key={index}
                                                        className="w-[135px] h-[150px] bg-[#FDF5EE] rounded-2xl pr-4"
                                                    >
                                                        <div className="flex">
                                                            <div className="relative overflow-hidden flex justify-center items-center border w-[45px] h-[45px] rounded-full bg-[#FFFFFF] border-[#FFDBB0] mt-3 ml-2">
                                                                <img
                                                                    src={`${client.user_profile_pic}` !== '' ?
                                                                        `${BASE_URL}${client.user_profile_pic}` :
                                                                        `${emptyavatar}`}
                                                                    alt="profile image"
                                                                    className="rounded-full w-[45px] h-[45px] object-cover"
                                                                />
                                                            </div>
                                                            <div className="bg-[#FDF5EE] rounded-[5px]">
                                                                <img
                                                                    src={notes}
                                                                    alt=""
                                                                    className="h-8 mt-4 ml-8 cursor-pointer"
                                                                    onClick={() => getClientNotes(client.user_id)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <p className="w-full pl-3 pt-2 text-[14px] font-medium">
                                                            {client.user_name}
                                                        </p>
                                                        <p className="w-full pl-3 pt-2 text-[13px] text-[#868686]">
                                                            {/* Upcoming Session */}
                                                            {client?.status ===
                                                                'pending'
                                                                ? `Did not attend`
                                                                : 'Attended'}
                                                        </p>
                                                        <p className="w-full pl-3 text-sm font-medium">
                                                            {client.session_time}
                                                        </p>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }
                                    )}
                                </Swiper>
                            ) : (
                                dashBoardInfo?.data?.datas_yesterday.map(
                                    (client, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="w-[135px] h-[150px] bg-[#FDF5EE] rounded-2xl pr-4"
                                            >
                                                <div className="flex">
                                                    <div className="relative overflow-hidden flex justify-center items-center border w-[45px] h-[45px] rounded-full bg-[#FFFFFF] border-[#FFDBB0] mt-3 ml-2">
                                                        <img
                                                            src={`${client.user_profile_pic}` !== '' ?
                                                                `${BASE_URL}${client.user_profile_pic}` :
                                                                `${emptyavatar}`}
                                                            alt="profile image"
                                                            className="rounded-full w-[45px] h-[45px] object-cover"
                                                        />
                                                    </div>
                                                    <div className="bg-[#FDF5EE] rounded-[5px]">
                                                        <img
                                                            src={notes}
                                                            alt=""
                                                            className="h-8 mt-4 ml-8 cursor-pointer"
                                                            onClick={() => getClientNotes(client.user_id)}
                                                        />
                                                    </div>
                                                </div>
                                                <p className="w-full pl-3 pt-2 text-[14px] font-medium">
                                                    {client.user_name}
                                                </p>
                                                <p className="w-full pl-3 pt-2 text-[13px] text-[#868686]">
                                                    {/* Upcoming Session */}
                                                    {client?.status ===
                                                        'pending'
                                                        ? `Did not attend`
                                                        : 'Attended'}
                                                </p>
                                                <p className="w-full pl-3 text-sm font-medium">
                                                    {client.session_time}
                                                </p>
                                            </div>
                                        )
                                    }
                                )
                            )}
                        </>
                    )
                    }
                </div>
            </div>

            {/* Recent Notes */}
            <div className='overflow-y-auto'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination={false}
                    scrollbar={false}
                    className="w-full recentNotes"
                >
                    {dashBoardInfo?.data?.recent_notes.length === 0 && (
                        <SwiperSlide className="bg-[#FFDBB0] rounded-2xl p-4 !w-full h-[150px]">
                            <div>
                                <div className="relative flex text-sm pb-1 font-ubuntu justify-between w-full">
                                    <p className="flex gap-2 pb-0 items-center font-constantia font-bold text-base">
                                        Recent Notes
                                    </p>
                                </div>

                                <div className="text-l pb-2 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                    No notes added yet!
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                    {dashBoardInfo?.data?.recent_notes.length >= 1 &&
                        dashBoardInfo?.data?.recent_notes.map((note, index) => (
                            <SwiperSlide
                                key={index}
                                className="bg-[#FFDBB0] rounded-2xl p-4 !w-full h-[155px]"
                            >
                                <div>
                                    <div className="relative flex text-sm pb-1 font-ubuntu justify-between w-full">
                                        <p className="flex gap-2 pb-0 items-center font-constantia font-bold text-base">
                                            Recent Notes
                                        </p>

                                        <div className="flex gap-2">
                                            <p className="text-gray-500 text-xs mt-2">
                                                {note.client_name}
                                            </p>
                                            <div className="flex justify-center items-center border w-[30px] h-[30px] rounded-full bg-[#FDF5EE] border-[#FFDBB0]">
                                                <img
                                                    src={`${note.client_profile_pic}` !== '' ?
                                                        `${BASE_URL}${note.client_profile_pic}` :
                                                        `${emptyavatar}`}
                                                    alt="profile image"
                                                    className="rounded-full w-[30px] h-[30px] object-cover"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-xs pb-2 max-h-[80px] min-h-[80px]  overflow-y-auto">
                                        {note.data
                                            .substring(0, 95)
                                            .replace(/(<([^>]+)>)/gi, '')
                                            .replace(/\&nbsp;/g, '')}
                                    </div>

                                    <div className="relative flex text-sm pb-3 mb-4 font-ubuntu justify-between w-full">
                                        <Link
                                            className="flex gap-2 pb-0 items-center text-[#F76411] text-xs underline decoration-dotted"
                                            to={`/coach-notes/${note.client_id}/${note.id}/`}
                                        >
                                            Edit Note
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>

        </aside>
    )
}

export default Left
