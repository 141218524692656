import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom'
import Container from '../../UI/landingPage/Container'
import logo from '../../assets/images/logo-livup.svg'
import HapsmithsVerticals from '../../assets/images/hap-design.svg'

const menuLinks = [
  {
    id: 1,
    text: "Home ",
    href: "#",
  },
  {
    id: 2,
    text: "Happiness",
    href: "#",
  },
  {
    id: 3,
    text: "About Us",
    href: "#",
  },
  {
    id: 4,
    text: "Testimonials",
    href: "#"
  },
  {
    id: 5,
    text: "Contact Us",
    href: "#",
  }
]

const Header = ({ menu, handleMenu }) => {

  useEffect(() => {
    AOS.init({ duration: 60000 })
  }, [])

  return (
    <>
      <header>
        <div className='flex flex-col'>
          <div className='h-[50vh] bg-[#0C1717]'>
            <Container>
              <div className='flex justify-between h-[60px] items-center py-[45px]'>
                <div className='flex flex-col' data-aos='fade-up'>                  
                    <Link to="/home" className='inline-block'>
                      <img src={logo} alt="Logo" className='inline-block w-[55px]' />
                      <span className='font-constantia text-[#fff] text-[40px] relative  bottom-[-8px] left-1'>Hapsmiths</span>
                    </Link>                                    
                    <span className='mt-[-1rem] font-ubuntu text-[15px] text-[#FFDBB0] font-thin inline-block relative left-[60px] bottom-[-8px]'>Your Department Of Happiness</span>                  
                </div>
                {/* <div className='text-[#fff]'>
                  <div className='w-[32px] h-[38px] relative rotate-0 cursor-pointer transition-all z-[60]' onClick={handleMenu}>
                    <span className={`block absolute h-[2px] w-[100%] bg-[#fff] rounded-[9px] opacity-100 left-0 transition-all ${!menu ? 'top-0 rotate-0' : 'top-[10px] rotate-[135deg]'}`}></span>
                    <span className={`block absolute h-[2px] w-[100%] bg-[#fff] rounded-[9px] top-[9px] rotate-0 transition-all ${!menu ? 'opacity-100 left-0' : 'opacity-0 left-[-60px]'}`}></span>
                    <span className={`block absolute h-[2px] w-[100%] bg-[#fff] rounded-[9px] opacity-100 left-0 transition-all ${!menu ? 'top-[19px] rotate-0' : 'top-[10px] rotate-[-135deg]'}`}></span>
                  </div>
                </div> */}
              </div>
            </Container>
          </div>
          <div className='h-[45vh] bg-[#fff]'></div>
        </div>

        <div className='flex absolute z-10 w-[100%] h-[calc(100vh-200px)] top-[110px] left-0'>
          <div className='bg-[#FDF5EE] w-[40%] h-[100%] absolute top-0'></div>
          {/* <div className='bg-[transparent] h-[100%] absolute top-0'></div> */}

          <Container className="after:content-[''] after:w-[100%] bg-[#FDF5EE] rounded-br-[20vw] z-[11] px-8 relative w-[100%]">
            <div className='flex justify-between items-center gap-4 w-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] px-8'>
              <div className='flex flex-col justify-center w-2/3' data-aos='fade-right'>
                <h1 className='font-constantia text-[55px] text-[#0C1717] leading-none font-semibold mb-5'>If you’re reading this,<br /> your company loves you</h1>
                <p className='font-constantia text-[30px] text-[#0C1717] leading-tight'>Get unlimited access to Mental health, Fitness and Habit coaches.</p>

                <Link to="/login" className='flex bg-[#F76411] text-[#FFDBB0] font-ubuntu  text-center w-[250px] h-[50px] rounded-[35px] font-bold  items-center justify-center mt-8'>Login</Link>
              </div>

              <div className='relative lp-lg:w-3/12 lp-mlg:w-1/3'>
                <img src={HapsmithsVerticals} alt="Hapsmiths Verticals" data-aos='fade-down' />
              </div>
            </div>
          </Container>
        </div>
      </header>
    </>
  )
}

export default Header