import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'
import gallery from '../../assets/images/lp-gallery.png'

const Gallery = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div className='bg-[#0C1717] py-20 pt-56 -mt-40'>
      <Container>
        <div className='w-[85%] mx-auto' data-aos='fade-up'>
          <img src={gallery} alt="gallery" />
        </div>
      </Container>
    </div>
  )
}

export default Gallery