import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'
import { useInView } from "react-intersection-observer";

const ContactUs = () => {

  const { ref, inView } = useInView();

  useEffect(() => {
    // console.log("It's in view Contact", inView);
    AOS.init({ duration: 2000 })
}, [inView])

  return (
    <section className='bg-[#0C1717] pb-2' ref={ref}>
      <Container>
        <div className='mb-3'>
          <span className='block text-[16px] text-[#F76411] font-medium relative after:content-[""] after:block after:w-[90px] after:h-[2px] after:bg-[#F76411] after:absolute after:-bottom-0 after:left-0 ' data-aos='fade-right'>Contact Us</span>
          <h2 className='text-[50px] text-[#FDF5EE] leading-none font-constantia font-medium mt-3' data-aos='fade-right'>Get in touch with us</h2>
        </div>

        <div className='flex items-center gap-8 py-10'>
          <div className='flex flex-col gap-1 mr-8'>
            <a href="tel:+0123456789" className='text-[#FFDBB0] text-[1.85rem]' data-aos='fade-right'>+0123456789</a>
            <a href="mailto:email@hapsmith.com" className='text-[#FFDBB0] text-[1.85rem]' data-aos='fade-right'>email@hapsmith.com</a>
          </div>

          <div className='border border-[#172B2B] rounded-[5rem] px-10 py-4 min-w-[15rem]'>
            <h4 className='text-[#FFDBB0] text-[18px] underline mb-2' data-aos='fade-right'>Support</h4>
            <a href="tel:+0123456789" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>+0123456789</a>
            <a href="mailto:email@hapsmith.com" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>email@hapsmith.com</a>
          </div>

          <div className='border border-[#172B2B] rounded-[5rem] px-10 py-4 min-w-[15rem]'>
            <h4 className='text-[#FFDBB0] text-[18px] underline mb-2' data-aos='fade-right'>Work with Us</h4>
            <a href="tel:+0123456789" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>+0123456789</a>
            <a href="mailto:email@hapsmith.com" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>email@hapsmith.com</a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ContactUs