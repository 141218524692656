export const clientData = [
    {
        name: 'Client01',
        type: 'Physical',
    },
    {
        name: 'Client02',
        type: 'Mental',
    },
    {
        name: 'Client03',
        type: 'Nutrition',
    },
    {
        name: 'Client04',
        type: 'Leadership',
    },
    {
        name: 'Client05',
        type: 'Nutrition',
    },
    {
        name: 'Client06',
        type: 'Nutrition',
    },
    {
        name: 'Client07',
        type: 'Nutrition',
    },
    {
        name: 'Client08',
        type: 'Nutrition',
    },
    {
        name: 'Client09',
        type: 'Nutrition',
    },
    {
        name: 'Client10',
        type: 'Nutrition',
    },
    {
        name: 'Client11',
        type: 'Nutrition',
    },
    {
        name: 'Client12',
        type: 'Nutrition',
    },
    {
        name: 'Client13',
        type: 'Nutrition',
    },
    {
        name: 'Client14',
        type: 'Nutrition',
    },
    {
        name: 'Client15',
        type: 'Nutrition',
    },
]
