import React, { useState } from 'react'
import Button from '../../../../UI/Button'
import ModalWrapper from '../../../../UI/ModalWrapper'
import { ToastContainer, toast } from 'react-toastify'
import api from '../../../../services/api'

const DeleteNoteModal = ({ deleteNoteId, onCloseModal, listNotes }) => {

    const [modalDisplay, setModalDisplay] = useState(true);

    const deleteNote = () => {
        if (deleteNoteId) {
            api.get(`employee/delete-note/${deleteNoteId}/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('livtoken')}`,
                },
            }).then((res) => {
                listNotes();
                toast.success(res.data.message);
                setTimeout(onCloseModal, 3000);
            })
        }
    }

    return (
        <ModalWrapper>
            <ToastContainer />
            <div className="bg-white rounded-3xl text-xl font-ubuntu px-12 py-12 w-[30vw]">
                <div className="font-semibold text-center mb-8">
                    Are you sure you want to delete this note?
                </div>

                <div className="flex justify-evenly gap-12 w-full mt-4 text-center">
                    <Button onClick={deleteNote} className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7">Yes</Button>
                    <Button onClick={onCloseModal} className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7">No</Button>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default DeleteNoteModal