import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Keyboard, Mousewheel } from 'swiper/core'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-coverflow'
import 'swiper/css/bundle'
import { Navigation, FreeMode } from 'swiper'
import { EffectCoverflow } from 'swiper'
import BorderDashed from '../../../UI/BorderDashed'
import { coaches } from './coaches'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../UI/Button'
import pillarTherapy from '../../../assets/images/pillarTherapy.png'
import pillarFitness from '../../../assets/images/pillarFitness.png'
import pillarDream from '../../../assets/images/pillarDream.png'
import pillarNutrition from '../../../assets/images/pillarNutrition.png'
import LGBTG from '../../../assets/images/LGBTQ.png'
import { useState } from 'react'
import Modal from './Modal'
import { useEffect } from 'react'
import { useGetCoachesMutation } from '../../../services/coachApi'
import { therapistActions } from '../../../services/therapistSlice'
import loader from '../../../assets/images/loader.gif'
import SessionExpired from '../../Common/SessionExpired'
import { BASE_URL } from '../../../keys'
import { ToastContainer, toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import defaultCoach from '../../../assets/images/default-coach.png'
SwiperCore.use([Keyboard, Mousewheel])

const Left = ({ gender, lgbtq, language }) => {
    const { id } = useParams()
    const background = useSelector((state) => state.therapistReducer.background)
    const categoryIcon = useSelector(
        (state) => state.therapistReducer.categoryIcon
    )
    const categoryId = window.location.search.at(-1)
    const currentCategory = window.location.pathname.split('/')[2]
    const [getCoaches, { isSuccess, isLoading, isError }] =
        useGetCoachesMutation()
    const slideRef = useRef(null)
    const swiperRef = useRef(null)
    const [modalDisplay, setModalDisplay] = useState(false)
    const dispatch = useDispatch()
    const coachList = useSelector((state) => state.therapistReducer.coachList)
    const [loading, setloading] = useState(false)
    const [swiper, setSwiper] = useState(null)
    const coachIdBN = useSelector((state) => state.therapistReducer.coachIdBN)
    const btnClick = useSelector((state) => state.therapistReducer.btnClick)
    const [indexCoachIdBN, setIndexCoachIdBN] = useState(1)

    // console.log('COACH LISTTTTT', coachList)

    const handleClick = () => {
        setModalDisplay(true)
        slideRef.current.click()
    }

    const [coachId, setCoachId] = useState(0)

    const getCoachesData = async () => {
        const formData = new FormData()
        formData.append('category', categoryId)
        formData.append('gender', gender)
        formData.append('lgbtq', lgbtq)
        formData.append('language', language)
        const response = await getCoaches(formData)
        const data = await response.data.data
        dispatch(therapistActions.getCoachList(data))
    }

    useEffect(() => {
        getCoachesData()

        //eslint-disable-next-line
    }, [background, gender, lgbtq, language])

    //redirect from chat page -- BookNow/Reschedule btn click
    useEffect(() => {
        if (coachIdBN) {
            //find the index of coachIdBN from coachlist array
            const index = coachList?.findIndex(
                (coach) => coach?.user_id == coachIdBN
            )
            setIndexCoachIdBN(index)
            // swiper.slideTo(indexCoachIdBN, 1000)
            // swiperRef.current.swiper.slideTo(indexCoachIdBN)
            // swiperRef.current.swiper.slideTo(+indexCoachIdBN)

            if (!localStorage.getItem('Booking_id') && !gender && !language) {
                setTimeout(handleClick, 1000)
            }
        }
    }, [coachIdBN, coachList, id])

    // console.log(id, 'ID PARAMS')

    useEffect(() => {
        if (localStorage.getItem('Booking_id')) {
            setloading(true)
            // toast.info('Wait a moment we are finding details', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            setTimeout(() => {
                document.location.reload()
                localStorage.removeItem('Booking_id')
                setloading(false)
            }, 2000)
        }
    }, [localStorage.getItem('Booking_id')])

    //Test this before push
    // useEffect(() => {
    //     const swiper = document.querySelector('.findTherapist').swiper
    //     // swiper.slideNext(400, false)
    //     // swiper.preventInteractionOnTransition = true
    //     // swiper.allowTouchMove = false
    //     // swiper.noSwiping = true
    //     // swiper.noSwipingClass = 'swiper-slide'

    //     // const swiper = new Swiper('.swiper', {
    //     //     speed: 400,
    //     //     spaceBetween: 0,
    //     //     preventInteractionOnTransition: true,
    //     // })
    // }, [coachList])

    return loading ? (
        <div className="absolute top-[150px] right-0 left-0">
            <p className="text-center">Loading Coachlist...</p>
        </div>
    ) : (
        <>
            {coachList.length === 0 && (
                <>
                    {!isLoading && (
                        <h1 className="w-[900px] text-center text-2xl font-constantia text-black flex justify-center align-middle absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <span>No coaches to show</span>
                        </h1>
                    )}
                </>
            )}
            {isLoading && (
                <>
                    <div className="flex w-[900px] justify-center align-middle absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <img
                            src={loader}
                            className="w-[200px] h-[200px] mt-[-20px] ml-[-20px] self-center"
                            alt="loader"
                        />
                    </div>
                </>
            )}
            {coachList.length > 0 && (
                <>
                    {!isLoading && (
                        <>
                            <div className="flex flex-col justify-start">
                                <Swiper
                                    ref={swiperRef}
                                    onSwiper={(swiper) => setSwiper(swiper)}
                                    effect={'coverflow'}
                                    mousewheel={true}
                                    direction="horizontal"
                                    // freeMode={false}
                                    allowTouchMove={false}
                                    // touchMove={false}
                                    // touchRatio={0}
                                    // noSwiping={true}
                                    // touchStartPreventDefault={false}
                                    // simulateTouch={false}
                                    // longSwipes={false}
                                    // touchMoveStopPropagation={true}
                                    // preventInteractionOnTransition={true}
                                    // preventClicksPropagation={true}
                                    // grabCursor={true}
                                    // momentum={false}
                                    // allowSlideNext={false}
                                    // preventClicks={true}
                                    // a11y={false}
                                    // watchSlidesProgress={true}
                                    // followFinger={false}
                                    // enabled={true}
                                    keyboard={true}
                                    centeredSlides={true}
                                    spaceBetween={0}
                                    slidesPerView={4}
                                    navigation={
                                        coachList.length >= 2 ? true : false
                                    }
                                    coverflowEffect={{
                                        rotate: 0,
                                        stretch: 0,
                                        depth: 100,
                                        modifier: 1,
                                        slideShadows: false,
                                    }}
                                    initialSlide={indexCoachIdBN}
                                    loop={coachList.length >= 4 ? true : false}
                                    pagination={false}
                                    noSwiping={true}
                                    className="findTherapist swiper-slide"
                                    modules={[
                                        // FreeMode,
                                        EffectCoverflow,
                                        Pagination,
                                        Navigation,
                                    ]}
                                >
                                    {isSuccess &&
                                        coachList.map((item, index) => (
                                            <SwiperSlide
                                                key={item.id}
                                                className="w-[400px] relative flex justify-center"
                                            >
                                                {({ isActive }) => (
                                                    <>
                                                        {isActive && (
                                                            <div
                                                                ref={slideRef}
                                                                onClick={() => {
                                                                    if (
                                                                        isActive
                                                                    ) {
                                                                        setCoachId(
                                                                            item.user_id
                                                                        )
                                                                    }
                                                                }}
                                                                className="absolute flex justify-center items-center w-16 rounded-full h-16 bg-white top-[-47px] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50"
                                                            >
                                                                <img
                                                                    src={
                                                                        BASE_URL +
                                                                        categoryIcon
                                                                    }
                                                                    className="w-7/12"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        )}
                                                        {!isActive && (
                                                            <div
                                                                className={`absolute flex justify-center items-center w-14 rounded-full h-14 top-[2px] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50`}
                                                                style={{
                                                                    backgroundColor: `${background}`,
                                                                }}
                                                            ></div>
                                                        )}
                                                        <div
                                                            className={` ${
                                                                isActive
                                                                    ? `relative w-[300px] overflow-hidden mt-[-50px] h-[460px] ml-[10px] mr-[10px] findTherapistActiveCards`
                                                                    : 'bg-heavy relative w-[240px] h-[335px] overflow-hidden z-0 findTherapistNonActiveCards'
                                                            }  rounded-[200px]`}
                                                            style={{
                                                                backgroundColor: `${
                                                                    isActive
                                                                        ? background
                                                                        : ''
                                                                }`,
                                                            }}
                                                        >
                                                            <h2 className="w-full text-center pt-12 font-bold font-constantia text-2xl px-4">
                                                                {item.name}
                                                            </h2>
                                                            <p className="text-sm text-center px-4 py-2 w-full font-ubuntu my-2">
                                                                {
                                                                    item.description
                                                                }
                                                            </p>
                                                            {isActive && (
                                                                <>
                                                                    <div className=" bg-[#ffffff]/[.3] rounded-md mx-2 px-2 py-2">
                                                                        <div className="bg-white rounded-md mb-2">
                                                                            <h4 className="text-sm font-ubuntu">
                                                                                <span className="p-1 bg-[#FDE9D8] inline-block min-w-[120px] rounded-br-2xl rounded-tl-md pl-3 text-[13px]">
                                                                                    Language
                                                                                </span>
                                                                            </h4>
                                                                            <div className="flex flex-wrap pl-3 py-2  text-sm font-ubuntu w-full">
                                                                                {item.languages.map(
                                                                                    (
                                                                                        elem,
                                                                                        i
                                                                                    ) => (
                                                                                        <span
                                                                                            key={
                                                                                                i
                                                                                            }
                                                                                            className="mr-2"
                                                                                        >
                                                                                            {
                                                                                                elem.name
                                                                                            }
                                                                                        </span>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="bg-white rounded-md">
                                                                            <h4 className="text-sm font-ubuntu">
                                                                                <span className="p-1 bg-[#FDE9D8] inline-block min-w-[120px] rounded-br-2xl rounded-tl-md pl-3 text-[13px]">
                                                                                    Specifications
                                                                                </span>
                                                                            </h4>
                                                                            <div className="flex flex-wrap pl-3 py-2  text-sm font-ubuntu w-full">
                                                                                {item.specializations.map(
                                                                                    (
                                                                                        elem,
                                                                                        i
                                                                                    ) => (
                                                                                        <span
                                                                                            key={
                                                                                                i
                                                                                            }
                                                                                            className="mr-2"
                                                                                        >
                                                                                            {
                                                                                                elem
                                                                                            }
                                                                                        </span>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {isActive &&
                                                                item.lgbtq ===
                                                                    'y' && (
                                                                    <img
                                                                        src={
                                                                            LGBTG
                                                                        }
                                                                        className="w-[50px] absolute bottom-[58px] left-[20px]"
                                                                        alt="lgbtq icon"
                                                                    />
                                                                )}

                                                            <img
                                                                src={
                                                                    `${item.profile_pic}` !=
                                                                    0
                                                                        ? `${BASE_URL}${item.profile_pic}`
                                                                        : `${defaultCoach}`
                                                                }
                                                                className={`w-[68%] absolute right-[0px] ${
                                                                    isActive
                                                                        ? 'bottom-[-12px]'
                                                                        : 'bottom-[0]'
                                                                }`}
                                                                alt="coach"
                                                                // w-9/12
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </SwiperSlide>
                                        ))}
                                </Swiper>
                                {!isLoading && (
                                    <div className="flex w-[1215px] max-w-[1215px] ml-[-10vw] justify-center findTherapistBookBtn">
                                        <Button
                                            onClick={handleClick}
                                            className="bg-[#FFDBB0] text-[#F77F11] font-bold w-[280px] ml-[-30px]"
                                        >
                                            Book
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {modalDisplay && (
                                <Modal
                                    coachId={coachId}
                                    categoryId={categoryId}
                                    closeModal={() => setModalDisplay(false)}
                                    btnClick={btnClick}
                                />
                            )}
                        </>
                    )}
                    <ToastContainer />
                </>
            )}
            {isError && <SessionExpired />}
        </>
    )
}

export default Left
