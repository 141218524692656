import React, { useState } from 'react'
import TimeRemaining from './Center/TimeRemaining'
import UserDetail from './Center/UserDetail'
import flaggedIcon from '../../../assets/images/flag-orange.png'
import exclamation from '../../../assets/images/exclamation-orange.png'
import Conversation from './Center/Conversation'
import ReportModal from './Right/Modals/ReportModal'
import { useSelector } from 'react-redux';

const Center = ({ socket, settingLastMsg }) => {

	const [modalDisplay, setModalDisplay] = useState(false);

	const [flaggedStatus, setFlaggedStatus] = useState("");
	const currentChatUser = useSelector((state) => state.coachReducer)

	// console.log('coachName', coachName);

	return (
		<div className="flex-[2] h-[92vh] p-2 ml-8 relative">
			<div className="w-full bg-white pt-6 rounded-[20px]  xl:h-[95%] 2xl:h-[97%] mt-10">
				<header className="w-[85%] mx-auto flex items-center h-10 justify-between">
					<UserDetail />
					<TimeRemaining />
					
                    {flaggedStatus &&
                        <div><img src={flaggedIcon} alt="flaggedIcon" className="mr-2 h-6" title='This client is flagged' /></div>
                    }

					<img onClick={() => setModalDisplay(true)} src={exclamation} className="w-6 cursor-pointer h-6 object-contain" alt="report" />

					{modalDisplay &&
						<ReportModal
							currentChatUser={currentChatUser}
							onCloseModal={() => setModalDisplay(false)}
						/>
					}

				</header>
				<hr className="border-heavy w-[85%] mx-auto my-3" />
				<Conversation socket={socket} settingLastMsg={settingLastMsg} />
			</div>
		</div>
	)
}

export default Center