import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    notifications: []
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationsData(state, action) {
            state.notifications = action.payload;
        },
        deleteNotificationData(state, action) {
            // console.log('notification data from deleteNotificationData', current(state)) // current is using for displaying the state data in console.
            state.notifications = state.notifications.filter((data) => data.id != action.payload)
        },
        updateNotificationStatus(state, action) {
            const newArr = state.notifications.map((p) => (
                p.id == action.payload
                    ? { ...p, ["status"]: 'r' }
                    : p
            ))
            state.notifications = newArr
        }
    }
})

export const { setNotificationsData,
    deleteNotificationData,
    updateNotificationStatus
} = notificationSlice.actions;

export default notificationSlice.reducer;