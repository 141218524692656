import React from 'react'
import Button from '../../../../UI/Button'
import ReactStars from "react-rating-stars-component";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../../services/api';
import { ToastContainer, toast } from 'react-toastify'
import Backdrop from '../../../../UI/Backdrop';


const FeedbackCoach = ({ feedbackClose }) => {

    const [livupRating, setLivupRating] = useState(1);
    const [coachRating, setCoachRating] = useState(1);
    const [inputValue, setInputValue] = useState("");

    const ratingChanged = (newRating) => {
        setLivupRating(newRating);
    };
    const newRatingChanged = (newRating) => {
        setCoachRating(newRating);
    };
    const currentChatUser = useSelector(state => state.chatUserReducer.user);

    console.log(currentChatUser, "bookingID and CoachID")

    const giveRatingHandler = async () => {
        const formData = new FormData();

        formData.append('client_id', currentChatUser?.user_id);
        formData.append('rating', coachRating);
        formData.append('booking_id', currentChatUser?.last_message.booking_id);
        formData.append('rating_type', 'client');
        formData.append('rating_exp_livup', livupRating);
        formData.append('feedback', inputValue);

        api.post(`employee/star-rating-client/`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("livtoken")}`,
            }
        }).then((res) => {
            console.log(res.data.data, "RES*** in Rating")
            toast.success('Thank you for updating your feedback!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            feedbackClose();
        }).catch((err) => {
            toast.warn(`${err}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }
    return (
        <>
        <Backdrop />
        <div className="fixed top-0 z-[9999] flex flex-col justify-between h-max bottom-0 left-0 right-0 m-auto w-[50vw] bg-white rounded-[30px] overflow-hidden">
            <header className='bg-heavy h-10 flex justify-center items-center text-center text-black text-md font-bold'>Feedback</header>

            <div className="w-full p-8">
                <span className="text-gray-500">Rate your Experience with Hapsmiths</span>
                <ReactStars
                    count={5}
                    value={livupRating}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#f76411"
                />
                <br />
                <span className="text-gray-500">Rate your Experience with the client</span>
                <ReactStars
                    count={5}
                    value={coachRating}
                    onChange={newRatingChanged}
                    size={24}
                    activeColor="#f76411"
                />
                <br />
                <span className="text-gray-500">Additional feedback</span> <small className="text-sm text-gray-400">(Optional)</small>
                <div>
                    <input
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        type="text" className="border-b-2 border-x-0 border-t-0 border-b-gray-500 focus:outline-none w-full my-2" />
                </div>
                <Button
                    onClick={giveRatingHandler}
                    className="bg-[#FFDBB0] text-[#F77F1F] h-[40px] rounded-[20px] w-[150px] mx-auto font-bold font-ubuntu">Submit</Button>
            </div>
        </div>
        </>
    )
}

export default FeedbackCoach