import React, { useState } from "react"
import plusIcon from "../../../assets/images/profile-plus.svg"
import { BASE_URL } from "../../../keys"
import { useGetCurrentUserQuery } from "../../../services/authApi"
import SessionExpired from "../../Common/SessionExpired"
import ProfilePictureModal from "./Modals/ProfilePictureModal"

const Left = () => {
	const [showModal, setShowModal] = useState(false)
	const { data, isError } = useGetCurrentUserQuery();
	const user = data?.data

	const toggleModal = () => {
		setShowModal((prevState) => !prevState)
	}

	return (
		<>
			<div className="w-[30%]">
				{user?.profile_pic.length > 1 ?
					<div className="w-96 h-96 mx-auto">
						<img onClick={toggleModal} className="w-96 h-96 rounded-full object-cover" src={BASE_URL + user?.profile_pic} alt="" />
					</div>

					:

					<div
						className="pt-[100%] relative bg-[#FDE9D8] rounded-[50%] border-2 border-[#FFC289] cursor-pointer"
						onClick={toggleModal}
					>
						<img src={plusIcon} alt="plus icon" className="w-[34px] h[34px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
					</div>
				}
			</div>
			{showModal && <ProfilePictureModal nameField={user?.name} dobField={user?.dob} genderField={user?.gender} onCloseProfileModal={toggleModal} />}
			{isError && <SessionExpired />}
		</>
	)
}

export default Left
