import React, { useState, useRef } from 'react'
import Input from '../../../Login/Input'
import Button from '../../../../UI/Button'
import { useNavigate } from 'react-router-dom';
import ModalWrapper from '../../../../UI/ModalWrapper'
import { useReportClientMutation } from '../../../../services/coachScreenApi';
import { ToastContainer, toast } from 'react-toastify'
import api from '../../../../services/api';
import { useEffect } from 'react';


function VideoJoinModalClient({ onCloseModal, videoLink }) {

    const form = useRef(null)
    const navigate = useNavigate();
    const [vLink, setvLink] = useState(null)
    const [loading, setloading] = useState(false)

    const handleReportUser = async (e) => {
        e.preventDefault();

    }

    useEffect(() => {
        setvLink(videoLink)
    }, [videoLink])


    return (
        <ModalWrapper>
            <ToastContainer />

            {
                vLink ? <div className="bg-white rounded-3xl text-xl text-center font-ubuntu px-12 py-12 w-6/12">
                    <span className="font-semibold">
                        Start the session?
                    </span>
                    <form ref={form} onSubmit={handleReportUser} >
                        <div className="flex gap-12 w-full mt-8 justify-center">
                            <a href={vLink} target="_blank" className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium cursor-pointer rounded-3xl">Join</a>
                            {/* <Button onClick={() => {
                                navigator.clipboard.writeText(vLink ? vLink : "");
                                toast.warn(`Link copied to clipboard`, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Copy</Button> */}
                            <Button onClick={onCloseModal} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Close</Button>
                        </div>
                    </form>
                </div> :
                    <div className="bg-white rounded-3xl text-xl text-center font-ubuntu px-12 py-12 w-6/12">
                        <div>
                            <p>Please contact your coach, some error occured.</p>
                        </div>
                        <div className="flex gap-12 w-full mt-8 justify-center">
                            <Button onClick={onCloseModal} className="bg-lighterOrange w-40 h-8 text-[17px] text-lightOrange font-medium">Close</Button>
                        </div>
                    </div>
            }
        </ModalWrapper>
    )
}

export default VideoJoinModalClient