import React, { useState, useEffect } from 'react'
import trashIcon from '../../../assets/images/trash-icon.png'
import pinNotes from '../../../assets/images/Pin_icon.png'
import moment from 'moment'
import api from '../../../services/api'
import DeleteNoteModal from '../Chat/Modals/DeleteNoteModal'

const NoteItem = ({
    item,
    selectedClient,
    handlePinnedUnpinned,
    setNewNotes,
    setEditNoteId,
    listNotes,
}) => {

    const editNote = (noteId) => {
        // console.log('noteId...', noteId)
        setNewNotes(true)
        setEditNoteId(noteId)
    }

    const [modalDisplay, setModalDisplay] = useState(false)

    return (
        <>
            <div className="relative border-[2px] border-[#FFDBB0] rounded-[20px] w-[170px]  min-h-[150px] max-h-[150px] cursor-pointer hover-pin">
                {item?.is_pinned == '1' && (
                    <img
                        src={pinNotes}
                        alt="pin icon"
                        className="absolute top-[0] left-[48%] w-[20px] translate-x-[-35%] translate-y-[-50%] cursor-pointer"
                        title="Click to unpin this note"
                        onClick={() =>
                            handlePinnedUnpinned({
                                noteId: `${item.id}`,
                                isPinned: 0,
                                clientId: `${selectedClient}`,
                            })
                        }
                    />
                )}

                <div className="flex">
                    <div className="flex w-full font-bold text-[.7rem] font-ubuntu pt-3 pl-4">
                        {moment(item.created_at).format('DD/MM/YYYY')}
                    </div>
                    <div className="flex pt-3 pr-4">
                        {/* {item?.is_pinned === "0" && <img src={pinNotes} alt="pin icon" className="relative top-[-3px] left-[-3px] w-[16px] cursor-pointer object-contain" />} */}

                        {item?.is_pinned == '0' && (
                            <img
                                src={pinNotes}
                                alt="pin icon"
                                className="absolute top-[0] left-[48%] w-[20px] translate-x-[-35%] translate-y-[-50%] cursor-pointer object-contain opacity-0 hover-img"
                                title="Click to pin this note"
                                onClick={() =>
                                    handlePinnedUnpinned({
                                        noteId: `${item.id}`,
                                        isPinned: 1,
                                        clientId: `${selectedClient}`,
                                    })
                                }
                            />
                        )}

                        <img
                            src={trashIcon}
                            alt="delete icon"
                            className=" w-[15px] h-[15px] cursor-pointer object-contain"
                            title="Click to delete this note"
                            onClick={() => setModalDisplay(true)}
                        />
                    </div>

                    {modalDisplay &&
                        <DeleteNoteModal
                            deleteNoteId={item.id}
                            // userFlaggedStatus={flaggedStatus}
                            onCloseModal={() => setModalDisplay(false)}
                            listNotes={listNotes}
                        />
                    }

                </div>
                <p className="w-full font-ubuntu text-gray-700 px-4 py-3 text-[12px] min-h-[75%]"
                    onClick={() => editNote(item.id)} >
                    {item?.data?.length >= 100
                        ? item?.data
                            ?.substring(0, 95)
                            .replace(/(<([^>]+)>)/gi, '') + `...`
                        : item?.data.replace(/(<([^>]+)>)/gi, '').replace(/\&nbsp;/g, '')}
                </p>
            </div>
        </>
    )
}

export default NoteItem
