import React, { useMemo } from 'react'
import Wrapper from '../../UI/Wrapper'
import Main from '../../components/Coach/Chat/Main'
import Sidebar from '../../components/Common/Sidebar'
import ClientNoteList from '../../components/Coach/Notes/ClientNoteList'
import NoteList from '../../components/Coach/Notes/NoteList'
import NewNote from '../../components/Coach/Notes/NewNote'
import ClientInfo from '../../components/Coach/Chat/ClientInfo'
import UpcomingSessions from '../../components/Coach/Notes/UpcomingSessions'
import { useEffect, useState } from 'react' 
import { useParams } from 'react-router-dom'
import {
    useGetClientNotesListQuery,
    usePinnedUnpinnedNotesQuery
} from '../../services/coachScreenApi'
import { useSelector } from 'react-redux'
import api from '../../services/api'
import { setNote } from '../../services/noteSlice'
import { useDispatch } from 'react-redux'

const Notes = () => {

    const { clientId: welcomeNoteClientId } = useParams()
    // console.log('****welcomeNoteClientId***********', welcomeNoteClientId)

    const { clientId: welcomeClientId, noteId: welcomeNoteId } = useParams()
    // console.log('*****CID, NID***********', welcomeClientId, welcomeNoteId)

    const [newNotes, setNewNotes] = useState(false)

    const [editNoteId, setEditNoteId] = useState(null)

    // console.log(welcomeNoteId, "welcomeNoteId");

    //Listing of clients who have notes
    const {
        data: clientListing,
        isLoading: clientListingLoading,
        isError,
        isSuccess: clientListingSuccess,
    } = useGetClientNotesListQuery()

    // console.log('CL', clientListing)

    const [selectedClient, setSelectedClient] = useState(0)

    const [newNote, setnewNote] = useState(0)

    const [pinnedvalues, setpinnedvalues] = useState({})

    const { noteId, isPinned, clientId } = useSelector(
        (state) => state.pinnedReducer
    )

    useEffect(() => {
        //to select the first client
        setSelectedClient(clientListing?.data[0]?.user_id)
        // setSelectedClient(2)
    }, [clientListing])


    //When client-id and note-id is coming from coach welcome page -- recent notes
    useEffect(() => {
        if (welcomeClientId && welcomeNoteId && clientListing) {
            const selectedWelcomeClient = clientListing?.data?.filter(
                (item) => item.user_id == welcomeClientId
            )

            setSelectedClient(selectedWelcomeClient[0]?.user_id)
            // setSelectedClient(2)
            setNewNotes(true) // Changing the note list view to new notes or edit notes  view
            setEditNoteId(welcomeNoteId)
        }
    }, [welcomeClientId, welcomeNoteId, clientListing])

    
    //When only client-id is coming from coach welcome page -- below client list
    useEffect(() => {
        if (welcomeNoteClientId && clientListing) {
            const selectedWelcomeClient = clientListing?.data?.filter(
                (item) => item.user_id == welcomeNoteClientId
            )

            setSelectedClient(selectedWelcomeClient[0]?.user_id)
            // setSelectedClient(2)
        }
    }, [welcomeNoteClientId, clientListing])


    //Pinned/Unpinned info
    //we need to pass noteId, isPinned 0 || 1, clientId
    const [pinnedUnpinned, setPinnedUnpinned] = useState({})

    const handlePandUp = ({ noteId, isPinned, clientId }) => {
        setPinnedUnpinned({
            noteId,
            isPinned,
            clientId,
        })
    }
    const { data } = usePinnedUnpinnedNotesQuery(pinnedUnpinned)

    //left client listing
    const toggleUser = (userId) => {
        setSelectedClient(userId)
        setNewNotes(0)
    }

    const getNewNote = (value) => {
        setNewNotes(value)
    }

    const setEditNote = (editId) => {
        setEditNoteId(editId)
    }

    // console.log("clientListing from Notes", clientListing)

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={false} />
                <Main>
                    <div className='container flex space-x-3 mx-auto'>
                    <ClientNoteList
                        clientListing={clientListing?.data}
                        clientListingLoading={clientListingLoading}
                        clientListingSuccess={clientListingSuccess}
                        selectedClient={selectedClient}
                        toggleUser={toggleUser}
                    />
                    {newNotes ? (
                        <NewNote
                            clientListing={clientListing?.data}
                            selectedClient={selectedClient}
                            editNoteId={editNoteId}
                            toggleUser={toggleUser}
                        />
                    ) : (
                        <NoteList
                            clientListing={clientListing?.data}
                            selectedClient={selectedClient}
                            getNewNote={getNewNote}
                            setEditNote={setEditNote}
                            handlePandUp={handlePandUp}
                        />
                    )}
                        <div className='flex-1 ml-8 h-[92%] mt-12'>
                            <div className='flex flex-col h-full'>
                            <ClientInfo
                            clientListing={clientListing?.data}
                            selectedClient={selectedClient}
                        />
                        <UpcomingSessions selectedClient={selectedClient} />
                            </div>
                        
                    </div>
                    </div>
                    
                </Main>
            </div>
        </Wrapper>
    )
}

export default Notes
