import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { useState } from 'react';
import Container from '../../UI/landingPage/Container'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import emptyavatar from '../../assets/images/emptyavatar.webp'
import quotes from '../../assets/images/quotes.png'
// import quotes from '../../assets/images/quotes.svg'


const data = [
  {
    id: 1,
    text: `"Hapsmtihs have been super convenient & effective with their process since the beginning. They have proven to successfully increase overall morale and productivity consistently."`,
    user_name: ``,
    user_designation: ``,
    user_image: ``,
  },
  {
    id: 2,
    text: `"Hapsmtihs have been super convenient & effective with their process since the beginning. They have proven to successfully increase overall morale and productivity consistently."`,
    user_name: ``,
    user_designation: ``,
    user_image: ``,
  },
  {
    id: 3,
    text: `"Hapsmtihs have been super convenient & effective with their process since the beginning. They have proven to successfully increase overall morale and productivity consistently."`,
    user_name: ``,
    user_designation: ``,
    user_image: ``,
  }
]
const Testimonials = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  const [swiperIndex, setSwiperIndex] = useState(1)

  return (
    <section className='bg-[#FDE9D8]'>
      <div className='relative z-[1]'>
        <div className='w-[50vw] h-[150px] bg-[#fff] rounded-r-[100px]'></div>
        <div className='-mt-[7.375rem]' data-aos='fade-right'>
          <Container>
            <span className='block text-[16px] text-[#F76411] font-medium relative after:content-[""] after:block after:w-[90px] after:h-[2px] after:bg-[#F76411] after:absolute after:-bottom-0 after:left-0 '>Testimonials</span>
            <h2 className='text-[50px] text-[#0C1717] leading-none font-constantia font-medium mt-3'>What our clients think</h2>
          </Container>
        </div>
      </div>

      <Container>
        <div className='pt-28 pb-20 px-40 -mt-8 bg-[#172B2B] w-full rounded-[500px] relative bg-no-repeat bg-[left_calc(8%)_top_calc(21%)]'
          style={
            {
              backgroundImage: `url(${quotes})`,
              backgroundSize: '16%'
            }
          } data-aos='fade-left'>
          {/* Swiper code here */}
          < Swiper
            pagination={{
              type: "progressbar",
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onActiveIndexChange={(swiperCore) => { setSwiperIndex(swiperCore.activeIndex + 1); }}
            className="testimonialsSwiper"
          >
            {
              data.map((testimonial) => (
                <SwiperSlide>
                  <div key={testimonial.id} className='w-full'>
                    <p className='text-[20px] text-[#FDF5EE] font-constantia mb-12'>{testimonial.text}</p>
                    <div className='flex gap-3 items-center'>
                      <img src={emptyavatar} alt="user image" className='w-[50px] rounded-full' />
                      <div className='flex flex-col gap-0'>
                        <h4 className='text-[#FDE9D8] text-[17px] font-medium'>Megna Jain</h4>
                        <span className='text-[13px] text-[#FFC289] underline underline-offset-3'>CEO at Dream a Dozen</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper >
          <div className='absolute bottom-[92px] right-[208px]'>
            <p className='text-[#fff]'>{swiperIndex}/{data.length}</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Testimonials