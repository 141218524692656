import React from 'react'
import Wrapper from '../UI/Wrapper'
import Main from '../components/Login/Main'
import Sidebar from '../components/Common/Sidebar'
import Left from '../components/Otp/Left'
import Right from '../components/Otp/Right'

const Otp = () => {
    
    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={true}/>
                <Main>
                    <Left />
                    <Right />
                </Main>
                <div className="fixed text-xs bottom-[40px] font-ubuntu xs:w-[200px] right-[40px]">
                Please note that your data is protected and won’t be used anywhere else.
                </div>
            </div>
        </Wrapper>
    )
}

export default Otp