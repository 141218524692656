import React, { useState, useRef, useEffect } from 'react'
import Calendar from 'react-calendar'
import { useDispatch, useSelector } from 'react-redux'
import {
    useBookSlotMutation,
    useCancelBookedSlotMutation,
    useCancelRelationMutation,
    useGetTimeSlotsMutation,
} from '../../../services/coachApi'
import { useGetCurrentUserQuery } from '../../../services/authApi'
import { slotActions } from '../../../services/slotSlice'
import CloseButton from '../../../UI/CloseButton'
// import { afternoonTimes, eveningTimes, morningTimes, nightTimes } from './times'
//eslint-disable-next-line
import { ToastContainer, toast } from 'react-toastify'
import BookingError from './Popups/BookingError'
import { bookingActions } from '../../../services/bookingSlice'
import Feedback from './Popups/Feedback'
import { useNavigate } from 'react-router-dom'
// import { coachActions } from '../../../services/coachSlice'
import loader from '../../../assets/images/loader.gif'
import moment from 'moment'
import Backdrop from '../../../UI/Backdrop'

// const timings = ['Morning', 'Afternoon', 'Evening', 'Night']
const currentTime = moment(new Date(), 'h:mm:ss A').format('HH:mm')
const currentDate = moment(new Date()).format('YYYY-MM-DD')

const Modal = (props) => {
    const [value, setValue] = useState(new Date())
    // const [currentTiming, setCurrentTiming] = useState('Morning')
    // for mutations, we have to destructure an array and an object INSIDE that array. u can extract name of the endpoint in array and the same isLoading, isSuccess, isError, isFetching from object.
    const [bookSlot, { isLoading: slotBookingLoading }] = useBookSlotMutation()
    const [
        getTimeSlots,
        { isLoading: slotsLoading, isError: getSlotsError, error },
    ] = useGetTimeSlotsMutation()
    // console.log('New ERR', error)
    const [cancelRelation, { isLoading: cancelLoading }] =
        useCancelRelationMutation()

    const { data: userDetails } = useGetCurrentUserQuery()

    //eslint-disable-next-line
    const [cancelBookedSlot, { isLoading: loadingToCancel }] =
        useCancelBookedSlotMutation()
    const slots = useSelector((state) => state.slotReducers.slots)
    const bookedSlots = useSelector((state) => state.slotReducers.booking)
    const leaveSlots = useSelector((state) => state.slotReducers.leaves)

    const dispatch = useDispatch()
    const [timeSlot, setTimeSlot] = useState('')

    const [message, setMessage] = useState('')
    const [errorBooking, setErrorBooking] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [feedback, setFeedback] = useState(false)
    const navigate = useNavigate()
    const bookingId = useSelector((state) => state.bookingReducer.bookingId)
    const coachId = useSelector((state) => state.bookingReducer.coachId)

    // console.log(bookingId, coachId, 'bookid', 'coachid')
    function formatDate() {
        var d = value,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
    }
    // console.log(formatDate(), 'value')

    const bookYourSlot = async () => {
        const formData = new FormData()

        formData.append('date', formatDate())
        formData.append('coach_id', props.coachId)
        formData.append('category_id', props.categoryId)
        formData.append('time_slot', timeSlot)

        try {
            const response = await bookSlot(formData)
            const data = await response.data
            console.log('data is this', data)
            // console.log(response)
            if (data) {
                // toast(response?.data?.code)
                toast.success(`Booked Successfully!`)
                setErrorBooking(false)
                //setting timeSLot to empty so that so Confirm btn is disable
                setTimeSlot('')

                //redirect to chat page
                setTimeout(() => {
                    navigate(`/chat/${props.coachId}`)
                }, 2000)
            }
            if (!response.error) {
                // setTimeout(() => {
                //   navigate("/chat")
                // }, 2000);
            }

            if (response.error.originalStatus === 500) {
                setErrorBooking(true)
                setErrorMessage('Something went wrong! Please try again.')
            } else {
                setErrorBooking(true)
                // setErrorMessage(response.error.data.data.error[0])
                
                //new - after wanting to display the coachName on Rating modal
                if ( response.error.data.data.error[0] === "Please give previous session a rating." ){
                    setErrorMessage(response.error.data.error_msgs[0]) //To display coachName on Rating Modal
                } else {
                    setErrorMessage(response.error.data.data.error[0])
                }
            }

            console.log(response.error)
            if (response.error.data.data.error[0]) {
                setErrorBooking(true)
                dispatch(
                    bookingActions.bookingError({
                        bookingId: response.error.data.data_key[0][0],
                        coachId: response.error.data.data_key[0][1],
                    })
                )
            }
        } catch (error) {
            console.log(error)
        }
    }

    const cancelBookedSlotHandler = async () => {
        const formData = new FormData()
        formData.append('booking_id', bookingId)
        formData.append('coach_id', coachId)
        formData.append('category_id', props.categoryId)

        // provide the data as parameter to your endpoint. We can provide the data in the format specified by the backend developer.
        const response = await cancelBookedSlot(formData)
        console.log(response) // long the response from api and do operations accordingly
        if (response.data.code === 'Success') {
            setErrorBooking(false)

            //vk
            //setting timeSLot to empty so that so Confirm btn is disable
            setTimeSlot('')
            toast.success('Cancelled Successfully!')
        }
    }

    const pauseRelationHandler = async () => {
        const formData = new FormData()

        formData.append('date', formatDate())
        formData.append('coach_id', props.coachId)
        formData.append('category_id', props.categoryId)
        formData.append('time_slot', timeSlot)
        formData.append('pause_relation', 'yes')

        try {
            const response = await cancelRelation(formData)
            //eslint-disable-next-line
            const data = await response.data
            console.log(response)

            // vk
            //setting timeSLot to empty so that so Confirm btn is disable
            setTimeSlot('')
            setErrorBooking(false)
            toast.success(
                'Paused previous relationship and selected slot booked successfully!'
            )
            // toast(
            //     `Slot Booked for ${moment(value).format(
            //         'DD-MM-YYYY'
            //     )} at ${timeSlot}`
            // )
            //redirect to chat page
            setTimeout(() => {
                navigate(`/chat/${props.coachId}`)
            }, 4000)

            if (response.error.data.data.error[0]) {
                dispatch(
                    bookingActions.bookingError({
                        bookingId: response.error.data.data_key[0][0],
                        coachId: response.error.data.data_key[0][1],
                    })
                )
            }
            // setErrorBooking(false)
        } catch (error) {
            console.log(error)
        }
    }
    const mainRef = useRef(null)

    const handleClick = async () => {
        const formData = new FormData()
        formData.append('date', formatDate())
        formData.append('coach_id', props.coachId)
        formData.append('category_id', props.categoryId)

        try {
            const response = await getTimeSlots(formData)
            const data = await response.data.data

            console.log(data.slots.slots)
            console.log('Resssssssss', response)
            if (response.data.code === 'Success') {
                dispatch(slotActions.getSlots(data.slots.slots))

                //vk
                dispatch(slotActions.getBookedSlots(data.booking))
                dispatch(slotActions.getLeaveSlots(data.leaves))

                if (data.slots.slots[0] === '') {
                    setMessage('No slots found! Try changing date.')
                } else {
                    setMessage('')
                }
            } else {
                console.log(response.error)
                dispatch(slotActions.getSlots([]))

                //vk
                dispatch(slotActions.getBookedSlots([]))
                dispatch(slotActions.getLeaveSlots([]))
            }
        } catch (error) {
            console.error('Errrr', error.message)
        }
    }

    useEffect(() => {
        handleClick()
        //eslint-disable-next-line
    }, [value])

    return (
        <>
            <Backdrop />

            <div className="fixed z-[999] w-screen h-screen top-0 left-0 flex justify-center items-center bg-[#4040407c] findTherapistCalModal">
                <ToastContainer hideProgressBar />

                {errorBooking && (
                    <BookingError
                        cancelBookedSlotHandler={cancelBookedSlotHandler}
                        loadingToCancel={loadingToCancel}
                        onClickYes={pauseRelationHandler}
                        cancelLoading={cancelLoading}
                        setFeedback={setFeedback}
                        setErrorBooking={setErrorBooking}
                        onClickNo={() => setErrorBooking(false)}
                        message={errorMessage}
                    />
                )}

                {feedback && (
                    <Feedback
                        setFeedback={setFeedback}
                        setErrorBooking={setErrorBooking}
                    />
                )}

                {!errorBooking && !feedback && (
                    <div className="w-5/12 md:w-9/12 xs:w-full sm:w-full bg-white rounded-2xl overflow-hidden">
                        <div
                            onClick={handleClick}
                            ref={mainRef}
                            className="h-10 w-full pt-2 mb-3 relative bg-[#FDE9D8] text-center font-bold"
                        >
                            Book Your Appointment
                            {props.btnClick === 'no' && (
                                <CloseButton
                                    onClick={props.closeModal}
                                    className="absolute top-2 right-10 cursor-pointer text-gray-500"
                                />
                            )}
                            {props.btnClick === 'yes' && (
                                <CloseButton
                                    onClick={() =>
                                        navigate(`/chat/${props.coachId}`)
                                    }
                                    className="absolute top-2 right-10 cursor-pointer text-gray-500"
                                />
                            )}
                        </div>

                        <Calendar
                            onChange={(e) => setValue(e)}
                            defaultValue={new Date()}
                            minDate={new Date()}
                            maxDate={
                                new Date(Date.now() + 6 * 24 * 60 * 60 * 1000)
                            }
                            className="mx-auto"
                            value={value}
                        />

                        <div
                            id="slotContainer"
                            className="flex flex-wrap justify-center gap-2 my-3 w-full px-10"
                        >
                            {/* When no slots are added for any day from Admin Panel */}
                            {error?.data.error_key === 'all_slots' && (
                                <p>All slots are booked for this date.</p>
                            )}

                            {!error?.data.error_key &&
                                getSlotsError &&
                                'An error occured! Either you may have booked this coach or you should try looking for another date'}
                            {message}

                            {!slotsLoading ? (
                                <>
                                    {/* When currentDate is same as selected date from calendar */}
                                    {!getSlotsError &&
                                        slots[0] !== '' &&
                                        moment(currentDate).format(
                                            'YYYY-MM-DD'
                                        ) ===
                                            moment(value).format(
                                                'YYYY-MM-DD'
                                            ) && (
                                            <>
                                                {/* Grayed slots previous to currentTime  */}
                                                {slots
                                                    .filter(
                                                        (item) =>
                                                            moment(
                                                                currentTime,
                                                                'h:mm:ss A'
                                                            ).format('HH:mm') >
                                                            moment(
                                                                item,
                                                                'h:mm:ss A'
                                                            ).format('HH:mm')
                                                    )
                                                    .map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className={
                                                                'p-1 flex items-center w-20 rounded-[40px] border-gray border-2 text-center text-gray-500 cursor-no-drop opacity-50'
                                                            }
                                                        >
                                                            <div className="w-2 h-2 rounded-full bg-gray-300 mr-2"></div>{' '}
                                                            {item}
                                                        </div>
                                                    ))}
                                                {/* Available slots greater than currentTime  */}
                                                {slots
                                                    .filter(
                                                        (item) =>
                                                            moment(
                                                                currentTime,
                                                                'h:mm:ss A'
                                                            ).format('HH:mm') <
                                                            moment(
                                                                item,
                                                                'h:mm:ss A'
                                                            ).format('HH:mm')
                                                    )
                                                    .map((item, index) => {
                                                        if (
                                                            bookedSlots.includes(
                                                                item
                                                            ) ||
                                                            leaveSlots.includes(
                                                                item
                                                            )
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={
                                                                        'p-1 flex items-center w-20 rounded-[40px] border-gray border-2 text-center text-gray-500 cursor-no-drop opacity-50'
                                                                    }
                                                                >
                                                                    <div className="w-2 h-2 rounded-full bg-gray-300 mr-2"></div>{' '}
                                                                    {item}
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    onClick={() =>
                                                                        setTimeSlot(
                                                                            item
                                                                        )
                                                                    }
                                                                    className={
                                                                        'active:bg-orange p-1 flex items-center w-20 cursor-pointer rounded-[40px] border-[#FFC289] border-2 text-center ' +
                                                                        ` ${
                                                                            timeSlot ===
                                                                            item
                                                                                ? ' bg-orange text-white'
                                                                                : ' text-gray-500'
                                                                        }`
                                                                    }
                                                                >
                                                                    <div className="w-2 h-2 rounded-full bg-heavyBorder mr-2"></div>{' '}
                                                                    {item}
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                            </>
                                        )}
                                    {/* When currentDate is not the selected date from calendar */}
                                    {!getSlotsError &&
                                        slots[0] !== '' &&
                                        moment(currentDate).format(
                                            'YYYY-MM-DD'
                                        ) !==
                                            moment(value).format(
                                                'YYYY-MM-DD'
                                            ) && (
                                            <>
                                                {slots?.map((item, index) => {
                                                    if (
                                                        leaveSlots.includes(
                                                            item
                                                        ) ||
                                                        bookedSlots.includes(
                                                            item
                                                        )
                                                    ) {
                                                        return (
                                                            <>
                                                                <div
                                                                    key={index}
                                                                    className={
                                                                        'p-1 flex items-center w-20 rounded-[40px] border-gray border-2 text-center text-gray-500 cursor-no-drop opacity-50'
                                                                    }
                                                                >
                                                                    <div className="w-2 h-2 rounded-full bg-gray-300 mr-2"></div>{' '}
                                                                    {item}
                                                                </div>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <div
                                                                key={index}
                                                                onClick={() =>
                                                                    setTimeSlot(
                                                                        item
                                                                    )
                                                                }
                                                                className={
                                                                    'active:bg-orange p-1 flex items-center w-20 cursor-pointer rounded-[40px] border-[#FFC289] border-2 text-center ' +
                                                                    ` ${
                                                                        timeSlot ===
                                                                        item
                                                                            ? ' bg-orange text-white'
                                                                            : ' text-gray-500'
                                                                    }`
                                                                }
                                                            >
                                                                <div className="w-2 h-2 rounded-full bg-heavyBorder mr-2"></div>{' '}
                                                                {item}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </>
                                        )}
                                </>
                            ) : (
                                'Loading'
                            )}
                        </div>

                        <div className="flex justify-center py-3 w-full">
                            <button
                                onClick={bookYourSlot}
                                // disabled={slotBookingLoading}
                                disabled={timeSlot ? false : true}
                                className=" w-36 bg-[#FFDBB0] text-[#F77F11] rounded-[40px] p-2 font-ubuntu font-bold disabled:opacity-15"
                            >
                                {slotBookingLoading ? (
                                    <img
                                        src={loader}
                                        className="w-8 h-8 mx-auto"
                                        alt=""
                                    />
                                ) : (
                                    'Confirm'
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Modal
