import React, { useState } from "react"
import Backdrop from "../../../../UI/Backdrop"
import ModalWrapper from "./ModalWrapper"
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { useUpdateProfileMutation } from '../../../../services/authApi'
import loader from '../../../../assets/images/loader.gif'

const DOBModal = (props) => {
	const [year, setYear] = useState(props.year);
	const [month, setMonth] = useState(+props.month - 1);
	const [day, setDay] = useState(props.day);
	const dateOfBirth = `${day}-${+month + 1}-${year}`;
	const [updateProfile, { isLoading }] = useUpdateProfileMutation();
	const [error, setError] = useState(false);

	const handleClick = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', props.nameField);
		formData.append('gender', props.genderField);
		formData.append('dob', dateOfBirth);

		const response = await updateProfile(formData);
		const data = await response.data;
		console.log(response);

		try {
			if (data.code === 'Success') {
				props.onCloseProfileModal();
				window.location.reload();
			}
		} catch (error) {
			console.log(error)
			setError(true)
		}

	}

	return (
		<div>
			<Backdrop />
			<ModalWrapper
				title="I was born in 2022, you?"
				onCloseProfileModal={props.onCloseProfileModal}
			>
				<form>
					<div className="w-[88%] mx-auto mt-7 mb-7">
						<div className="flex justify-center items-center space-x-4">
							<input type="text" name="dob" className="hidden" value={props.dobField} readOnly />
							{/* value={dateOfBirth} */}
							<YearPicker
								defaultValue={"Year"}
								start={1900}
								end={new Date().getFullYear() - 18}
								reverse
								required={true}
								value={year}
								onChange={(e) => setYear(e)}
								id={'year'}
								classes={'p-2 bg-[#FDF5EE] mx-2 border-[#FFDBB0] w-[100px] text-center border-2 rounded-[20px]'}
								optionClasses={'option classes'}
							/>
							<MonthPicker
								defaultValue={"Month"}
								endYearGiven    // mandatory if end={} is given in YearPicker
								year={year}     // mandatory
								required={true} // default is false
								value={month}   // mandatory
								onChange={(e) => setMonth(e)}
								id={'month'}
								classes={'p-2 bg-[#FDF5EE] mx-2 border-[#FFDBB0] w-[100px] text-center border-2 rounded-[20px]'}
								optionClasses={'option classes'}
							/>
							<DayPicker
								defaultValue={'Day'}
								year={year}    // mandatory
								month={month}  // mandatory
								endYearGiven   // mandatory if end={} is given in YearPicker
								required={true}
								disabled={!month ? true : false}
								value={day}    // mandatory
								onChange={(e) => setDay(e)}
								id={'day'}
								classes={'p-2 bg-[#FDF5EE] mx-2 border-[#FFDBB0] w-[100px] text-center border-2 rounded-[20px]'}
								optionClasses={'option classes'}
							/>
						</div>
						{error && <span className="text-orange text-sm">Something went wrong! Please try again or try changing the inputs.</span>}

						<div className="flex justify-center items-center space-x-5">
							<button
								type="button"
								className="bg-[#FFDBB0] text-[#F77611] font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
								onClick={(e) => {
									handleClick(e);
									props.setDobField(dateOfBirth);
								}}
							>
								{isLoading ?
									<img src={loader} className="w-8 h-8 mx-auto" alt="" />
									:
									"Save"
								}
							</button>
						</div>
					</div>
				</form>
			</ModalWrapper>
		</div>
	)
}

export default DOBModal
