import React from 'react'
import { useState } from 'react';
import FAQModal from './Modals/FAQModal';
import { questions } from './questions'

const FAQ = () => {
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [modalDisplay, setModalDisplay] = useState(false)

    return (
        <section className="bg-white w-[65%] h-[75vh] overflow-y-scroll rounded-[20px] py-6 px-12">
            <h1 className="text-2xl mb-[40px] mt-3 text-black font-ubuntu font-bold">FAQs</h1>

            {questions.map((item, i) => (
                <div onClick={() => { setQuestion(item.text); setAnswer(item.answer); setModalDisplay(true) }} key={i} className={` ${(i !== 0) ? "border-b-[1px]" : "border-y-[1px]"} border-orange  py-2 flex items-center w-[95%] mx-auto cursor-pointer`}>
                    <div className={`${question === item.text ? "bg-light": ""} rounded-2xl flex items-center w-full p-3`}>
                        {item.text}
                    </div>
                </div>
            ))}
            {modalDisplay && <FAQModal onClick={() => setModalDisplay(false)} question={question} answer={answer} />}

        </section>
    )
}

export default FAQ