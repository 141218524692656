import React from 'react'
import { useSelector } from 'react-redux'
import Sidebar from '../components/Common/Sidebar'
import Main from '../components/FormScreen/Main'
import FAQ from '../components/Settings/FAQ'
import Menu from '../components/Settings/Menu'
import Support from '../components/Settings/Support'
import PrivacyPolicy from '../components/Settings/PrivacyPolicy'
import TermsnConditions from '../components/Settings/TermsnConditions'
import Wrapper from '../UI/Wrapper'

const Settings = () => {
  const currentOption = useSelector((state) => state.settingsReducer.currentOption);

  return (
    <Wrapper>
      <div className="flex">
        <Sidebar page="settings" />
        <Main>
          <div className="w-[80%] h-[80%] flex gap-2 mx-auto my-auto">
            <Menu />
            {currentOption === 'FAQs' && <FAQ />}
            {currentOption === 'Support' && <Support />}
            {currentOption === 'Privacy Policy' && <PrivacyPolicy />}
            {currentOption === 'Terms & Conditions' && <TermsnConditions />}
          </div>
        </Main>
      </div>
    </Wrapper>
  )
}

export default Settings