import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'
import verticalsDesign from '../../assets/images/lp-footer-verticals.png'
import { Link } from 'react-router-dom'
import facebookIcon from '../../assets/images/lp-facebook.png'
import linkedinIcon from '../../assets/images/lp-linkedin.png'
import twitterIcon from '../../assets/images/lp-twitter.png'
import logo from '../../assets/images/logo-livup.svg'

const footerLinks = [
  {
    id: 1,
    text: "Menu",
    link: "#"
  },
  {
    id: 2,
    text: "Menu",
    link: "#"
  },
  {
    id: 3,
    text: "Menu",
    link: "#"
  },
  {
    id: 4,
    text: "Menu",
    link: "#"
  },
  {
    id: 5,
    text: "Menu",
    link: "#"
  }
]

const socialLinks = [
  {
    id: 1,
    icon: twitterIcon,
    link: "#"
  },
  {
    id: 2,
    icon: facebookIcon,
    link: "#"
  },
  {
    id: 3,
    icon: linkedinIcon,
    link: "#"
  }
]

const year = new Date().getFullYear()

const Footer = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])
  
  return (
    <div className='bg-[#0C1717]'>
      <Container>
        <div className='flex gap-8'>
          <div className='w-[25%]'>
            <img src={verticalsDesign} alt="hapsmiths verticals" data-aos='fade-up'/>
          </div>

          <div className='flex w-[75%] justify-between items-center'>
            <div className='flex flex-col gap-10 w-[75%]'>
              <ul className='flex gap-10'>
                {footerLinks?.map(link => (
                  // <li key={link.id} className="text-[15px] text-[#FDE9D8] relative block after:content-[' '] after:absolute after:w-full after:scale-x-0 after:h[2px] after:bottom-0 after:left-0 after:bg[#F76411] after:origin-bottom-right after:transition-transform hover:after:scale-x-0 hover:after:origin-bottom-left">{link.text}</li>
                  <li key={link.id} className="text-[15px] text-[#FDE9D8] relative block uLine-animation">
                    <Link to={link.link}>{link.text}</Link>
                  </li>
                ))}
              </ul>
              <div>
                <h4 className='text-[#FDF5EE] font-semibold'>Info:</h4>
                <p className='text-[#FDF5EE] text-[15px] font-light'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massasit amet sapien fringilla, mattis ligula consectetur.</p>
              </div>
              <div>
                <ul className='flex gap-3 text-[#FDF5EE] font-light text-[14px]'>
                  <Link to='/terms-conditions-privacy?1' target="_black">Terms and Conditions</Link>
                  <Link to='/terms-conditions-privacy?2' target="_black">Privacy Policy</Link>
                </ul>
                <div className='text-[#FDF5EE] font-light text-[14px]'>
                  All rights reserved © Hapsmiths {year}
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-8 items-end w-[25%]' data-aos='fade-up'>
              <ul className='flex flex-col gap-2 items-center'>
                {socialLinks.map((item) => (
                  <Link key={item.id} to={item.link} target='_blank'>
                    <img src={item.icon} alt="social icons" />
                  </Link>
                ))}
              </ul>

              <Link to="/home" className='flex justify-center items-center'>
                <img src={logo} alt="Logo" className='inline-block w-[45px]' />
                <span className='font-constantia text-[#fff] text-[30px]'>Hapsmiths</span>
              </Link>
            </div>
          </div>
        </div >
      </Container >
    </div >
  )
}

export default Footer