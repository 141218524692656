import React from 'react'
import OptionButton from '../../../UI/OptionButton'
import addMore from '../../../assets/images/addmore.png'
import { useGetLanguagesQuery } from '../../../services/categoryApi'
import loader from '../../../assets/images/loader.gif'
import SimpleBar from 'simplebar-react'
import { useState } from 'react'
import { useEffect } from 'react'

const Right = ({
    setGender,
    gender,
    setLgbtq,
    setLanguage,
    lgbtq,
    language,
}) => {
    const { data, isLoading, isSuccess, isError } = useGetLanguagesQuery()
    console.log('Languages', data)

    const [loading, setloading] = useState(false)
    useEffect(() => {
        if (localStorage.getItem('Booking_id')) {
            setloading(true)
            setTimeout(() => {
                setloading(false)
            }, 2000)
        }
    }, [localStorage.getItem('Booking_id')])

    return loading ? (
        <div className="absolute top-[150px] right-0 left-0">
            <p className="text-center hidden">Loading...</p>
        </div>
    ) : (
        <>
            <div className="fixed z-50 w-[240px] top-[0] right-[3vw] pt-16 px-3 bg-white h-[83vh] rounded-b-[100px]  findTherapistFilterBy">
                <h1 className="font-constantia text-xl">Filter by</h1>
                <SimpleBar style={{ maxHeight: '50vh' }}>
                    <h5 className="text-sm mt-10 text-gray-500">Gender</h5>
                    <div className="flex pb-3 pt-2 border-b-light border-b-[1px]">
                        <OptionButton
                            className={
                                gender === 'Male'
                                    ? ' bg-orange text-white'
                                    : 'bg-light text-gray-600'
                            }
                            onClick={() => setGender('Male')}
                        >
                            Male
                        </OptionButton>
                        <OptionButton
                            className={
                                gender === 'Female'
                                    ? ' bg-orange text-white'
                                    : 'bg-light text-gray-600'
                            }
                            onClick={() => setGender('Female')}
                        >
                            Female
                        </OptionButton>
                    </div>
                    <OptionButton
                        className={
                            lgbtq === 'y'
                                ? 'bg-orange text-white'
                                : 'bg-light text-gray-600'
                        }
                        onClick={() => {
                            lgbtq === 'y' ? setLgbtq('') : setLgbtq('y')
                        }}
                    >
                        LGBTQ
                    </OptionButton>
                    {/* </SimpleBar> */}
                    <h5 className="text-sm mt-10 text-gray-500">Languages</h5>
                    {/* <SimpleBar style={{ maxHeight: 140 }}> */}
                    <div className="flex flex-wrap w-full pb-1 pt-2 border-b-light border-b-[1px]">
                        {isLoading && (
                            <img src={loader} className="w-10 h-10" alt="" />
                        )}
                        {!isLoading &&
                            isSuccess &&
                            data?.data?.map((item, index) => (
                                <OptionButton
                                    key={index}
                                    className={
                                        language === item.id
                                            ? ' bg-orange text-white'
                                            : 'bg-light text-gray-600'
                                    }
                                    onClick={() => {
                                        language === item.id
                                            ? setLanguage('')
                                            : setLanguage(item.id)
                                    }}
                                >
                                    {item.name}
                                </OptionButton>
                            ))}
                        {isError && (
                            <>
                                <span className="text-orange text-sm">
                                    Error fetching languages
                                </span>
                            </>
                        )}
                    </div>
                </SimpleBar>
                {/* <img src={addMore} className="w-10 h-10 my-2" alt="add more" /> */}
                <div
                    onClick={() => {
                        setGender('')
                        setLgbtq('')
                        setLanguage('')
                    }}
                    className="text-sm text-orange cursor-pointer font-semibold mt-3"
                >
                    Clear All
                </div>
            </div>
        </>
    )
}

export default Right
