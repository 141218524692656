import React from 'react'
import { useEffect, useState } from 'react'
import Wrapper from '../../UI/Wrapper'
import Main from '../../components/Coach/Chat/Main'
import Sidebar from '../../components/Common/Sidebar'
import ClientInfo from '../../components/Coach/Chat/ClientInfo'
import ClientList from '../../components/Coach/Chat/ClientList'
import Conversation from '../../components/Coach/Chat/Conversation'
import QuickNotes from '../../components/Coach/Chat/QuickNotes'
import { useGetClientChatListQuery } from '../../services/coachScreenApi'
import io from 'socket.io-client'
import { useSelector } from 'react-redux'
import { CHAT_URL } from '../../keys'

const socket = io.connect(CHAT_URL, { transports: ['websocket'] })

const Chats = () => {

    //Listing of clients who have notes
    const {
        data: clientListing,
        isLoading: clientListingLoading,
        isError,
        isSuccess: clientListingSuccess,
    } = useGetClientChatListQuery()
    // console.log('Chat List from Coach Chat Page', clientListing)

    //   const [selectedClient, setSelectedClient] = useState(0);

    const currentChatUser = useSelector(state => state.chatUserReducer.user)
    const selectedClient = currentChatUser?.user_id;
    // const selectedClient = 2;

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={false} />
                <Main>
                    <div className='container flex space-x-3 mx-auto '>
                        <ClientList
                            socket={socket}
                            clientListing={clientListing?.data}
                            clientListingLoading={clientListingLoading}
                        />
                        <Conversation socket={socket} />
                        <div className='flex-1 ml-8 h-[92%] mt-12'>
                            <div className='flex flex-col h-full'>
                                <ClientInfo
                                    clientListing={clientListing?.data}
                                    selectedClient={selectedClient}
                                />
                                <QuickNotes
                                    clientListing={clientListing?.data}
                                    selectedClient={selectedClient}
                                />
                            </div>
                        </div>

                    </div>

                </Main>
            </div>
        </Wrapper>
    )
}

export default Chats