import React, { useEffect, useState } from 'react'
import { useGetCurrentUserQuery } from '../../../services/authApi';
import {
	setNotificationsData,
	deleteNotificationData,
	updateNotificationStatus
}
	from '../../../services/notificationSlice'
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../services/userSlice';
import SessionExpired from '../../Common/SessionExpired';
import api from '../../../services/api';
import moment from 'moment';


const Activities = () => {

	const dispatch = useDispatch();
	const [noData, setNoData] = useState(false)

	const notifications = useSelector((state) => state.notifications.notifications)
	const { data, isLoading, isError, isSuccess } = useGetCurrentUserQuery();

	useEffect(() => {
		dispatch(userActions.setUser(data?.data))
	}, [])


	useEffect(() => {
		api.get(`common/get-notifications/?page_no=1`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("livtoken")}`,
			}
		}).then((res) => {
			dispatch(setNotificationsData(res.data.data))
			if (res?.data?.data.length == 0) {
				setNoData(true)
			}
		})
	}, []);


	useEffect(() => {
		const activeElements = [...document.querySelectorAll('.activeElements')];
		const closeButtons = [...document.querySelectorAll('.closeButton')];
		function removeAll() {
			activeElements.forEach((item, i) => {
				item.classList.remove('bg-[#FDF5EE]')
				closeButtons[i].style.display = 'none';
			})
		}
		activeElements.forEach((item, i) => {
			item.addEventListener('click', function () {
				// console.log('clicked');
				removeAll();

				this.classList.add('bg-[#FDF5EE]');
				closeButtons[i].style.display = 'flex';
			})
		})
	}, [notifications]);



	const deleteNotification = async (notificationId) => {
		api.get(`common/update-notification/${notificationId}/?status=d`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("livtoken")}`,
			}
		}).then((res) => {
			dispatch(deleteNotificationData(notificationId))
		})
	}

	const changeNotificationStatus = async (notificationId) => {
		api.get(`common/update-notification/${notificationId}/?status=r`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem("livtoken")}`,
			}
		}).then((res) => {
			dispatch(updateNotificationStatus(notificationId))
		})
	}

	return (
		<aside className="bg-white rounded-2xl w-[300px] p-4 h-[92%] mt-8 ml-20 overflow-y-hidden ">
			{isLoading && <h1 className="w-full mt-10 font-bold text-center text-3xl font-constantia">Loading...</h1>}
			{!isLoading && isSuccess && <h1 className="w-full mt-10 font-bold text-center text-3xl font-constantia">Good {
				new Date().getHours() < 12 ? "Morning" : (new Date().getHours() >= 12 && new Date().getHours() < 17) ? "Afternoon" : "Evening"}, <br /> {data?.data?.name}</h1>}

			{data?.data?.upcoming_sessions == 0 ? <p className="font-ubuntu w-full text-center mt-5">You don't have any activity yet, <br /> select a vertical to begin.</p> : <p className="font-ubuntu w-full text-center mt-5"><br /></p>}

			<div className="flex text-sm mt-16 text-gray-600 border-b-[1px] pb-1 mb-4 border-b-orange-200 font-ubuntu justify-between w-full">
				<p>Notifications</p>
				<p className="flex gap-2 items-center">
					{moment().format('DD-MMM-YYYY')}
				</p>
			</div>

			<SimpleBar className="w-full  flex flex-col gap-3  xl:h-[47vh] 2xl:h-[60vh] " forceVisible="y" autoHide={false}>
				{notifications.map((item, i) => {
					return (
						<>
							<div key={i} onClick={() => changeNotificationStatus(item.id)} id={`rowId_` + item.id} data-rowId={item.id} className={`w-full p-3 rounded-xl activeElements pr-5 text-xs font-ubuntu relative cursor-pointer ${item.status == 'r' && `text-gray-500`}`}>
								<span className={`absolute left-0 w-[2px] top-[16px] h-8 rounded-sm 
									${item.category_id == 4 ? 'bg-orange' : ''}
									${item.category_id == 3 ? 'bg-yellow' : ''}
									${item.category_id == 2 ? 'bg-lightblue' : ''}
									${item.category_id == 1 ? 'bg-green' : ''}`}>
								</span>
								{item.noti_text}
							</div>
							<div className={`closeButton hidden absolute h-5 w-5 text-[14px] rounded-full text-lg justify-center text-center top-[0] right-[5px] bg-[#FDF5EE] cursor-pointer text-red-600`} onClick={() => deleteNotification(item.id)}>x</div>
						</>
					)
				})}
				{noData &&
					<div className="mt-10 text-center text-sm font-ubuntu text-lightOrange">No new notifications!</div>
				}
			</SimpleBar>


			{isError && <SessionExpired />}

		</aside>
	)
}

export default Activities