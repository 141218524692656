import React, { useState, useRef } from 'react'
import Button from '../../../../../UI/Button';
import ModalWrapper from '../../../../../UI/ModalWrapper';
import { useReportCoachMutation } from '../../../../../services/coachApi';
import { ToastContainer, toast } from 'react-toastify'

const ReportModal = ({ onCloseModal, currentChatUser }) => {

    // console.log('currentChatUser from Report Coach Modal', currentChatUser);
    const form = useRef(null)

    const [report, { isLoading }] = useReportCoachMutation();

    const [reason, setReason] = useState('');

    const handleChange = event => {
        setReason(event.target.value);
    };

    const handleReportUser = async (e) => {
        e.preventDefault();
        if (reason.trim().length !== 0) {
            // console.log('reason is NOT empty');
            const formData = new FormData(form.current);
            formData.append('coach_id', currentChatUser?.coach_id);
            const response = await report(formData);
            const data = await response.data;

            try {
                if (data.code === 'Success') {
                    // toast.success(data.message);
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => { onCloseModal() }, 3000);
                }
            } catch (error) {
                toast.error("Some error occured! Please try again.");
            }
        } else {
            // console.log('reason is empty');
            toast.error('Please add a reason and submit.');
        }
    }

    return (
        <ModalWrapper>
            <ToastContainer />
            <div className="bg-white rounded-3xl text-xl font-ubuntu px-12 py-12 w-[35vw]">

                <div className="font-semibold !text-center">
                    Report this Coach?
                </div>

                <div class="w-full mt-6 mb-3">
                    <div class="text-gray-500 text-sm">Please contact our customer care number</div>
                    <div class="text-lightOrange">+91-9876543210</div>
                </div>
                <div class="flex w-full mb-6">
                    <div class="w-4/12 mt-3 h-[1px] border-[2px] border-[#FFE2C0]"></div>
                    <div class="w-4/12 text-gray-500 text-center">OR</div>
                    <div class="w-4/12 mt-3 h-[1px] border-[2px] border-[#FFE2C0]"></div>
                </div>

                {/* <form ref={form} onSubmit={handleReportUser} > */}
                <label className="font-ubuntu text-[#868686] text-[14px] text-left !leading-normal">
                    Please state the reason to report {currentChatUser?.coachName}
                </label>
                <form ref={form} onSubmit={handleReportUser} >
                    <div className="flex w-full mt-1">
                        <textarea onChange={handleChange} name="reason" id="reason" placeholder={"Specify"} type="text" className="w-full p-1 text-[#F76411] text-[14px] outline-none border-b border-[#D9D9D9] focus:border-[#F76411] active:border-[#F76411] pr-6 placeholder:text-[#868686] placeholder:text-[12px] mt-3 !leading-normal" rows={4}></textarea>
                    </div>
                    <div className="flex justify-evenly gap-12 w-full mt-4 text-center">
                        <Button className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7">Submit</Button>
                        <Button onClick={onCloseModal} className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7">Close</Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
}

export default ReportModal