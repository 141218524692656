import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coachName: '',
    roomId: null,
    coach_category: null,
    coach_id: null,
    sessionDate: null,
    sessionTime: null,
    isRelationPaused: null,
    coach_category_id: null,
    last_message: null
}

export const coachSlice = createSlice({
    name: 'coach',
    initialState,
    reducers: {
        toggleCoach(state, action) {
            state.coachName = action.payload.name;
            state.roomId = action.payload.roomId;
            state.coach_category = action.payload.coach_category;
            state.coach_id = action.payload.coach_id;
            state.sessionDate = action.payload.sessionDate;
            state.sessionTime = action.payload.sessionTime;
            state.isRelationPaused = action.payload.isRelationPaused;
            state.coach_category_id = action.payload.coach_category_id;
            state.last_message = action.payload.last_message
        }
    }
})

export const coachActions = coachSlice.actions;
export const coachReducer = coachSlice.reducer