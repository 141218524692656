import React from 'react'

const ModalWrapper = (props) => {
  return (
    <div className='fixed z-[999] w-screen h-screen top-0 left-0 flex justify-center items-center bg-[#4040407c]'>
        {props.children}
    </div>
  )
}

export default ModalWrapper