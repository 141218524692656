import React, { useState } from "react"
import Backdrop from "../../../../UI/Backdrop"
import ModalWrapper from "./ModalWrapper"
import editIconGray from "../../../../assets/images/edit icon gray.svg"
import { useUpdateProfileMutation } from '../../../../services/authApi'
import loader from '../../../../assets/images/loader.gif'

const NameModal = (props) => {
	// when using a mutation hook, destructure an ARRAY and an object
	//eslint-disable-next-line
	const [updateProfile, { isLoading }] = useUpdateProfileMutation();
	const [error, setError] = useState(false)

	const handleClick = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', props.nameField);
		formData.append('gender', props.genderField);
		formData.append('dob', props.dobField);

		const response = await updateProfile(formData);
		const data = await response.data;
		// console.log(response);

		try {
			if (data.code === 'Success') {
				props.onCloseProfileModal();
				window.location.reload();
			}
		} catch (error) {
			console.log(error)
			setError(true)
		}
	}


	return (
		<div>
			<Backdrop />
			<ModalWrapper
				title="Name"
				onCloseProfileModal={props.onCloseProfileModal}
			>
				<form>
					<div className="w-[88%] mx-auto mt-7">
						<div className="flex justify-between items-center relative">
							<input
								type="text"
								className="w-full p-1 text-[#F76411] outline-none  border-b border-[#D9D9D9] focus:border-[#F76411] active:border-[#F76411] pr-6"
								value={props?.nameField ?? props.currentName}
								onChange={(event) => props.setNameField(event.target.value)}
							/>
							<img
								src={editIconGray}
								alt="edit icon"
								className="w-[22px] h-[17.27px] object-contain absolute right-0"
							/>
						</div>
						{error && <span className="text-orange text-sm">Something went wrong! Please try again!</span>}
						<div className="flex justify-center items-center space-x-5">
							<button
								type="submit"
								onClick={handleClick}
								disabled={isLoading}
								className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7"
							>
								{isLoading ?
									<img src={loader} className="w-8 h-8 mx-auto" alt="" />
									:
									"Save"
								}

							</button>
						</div>
					</div>
				</form>
			</ModalWrapper>
		</div>
	)
}

export default NameModal
