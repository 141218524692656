import React from 'react'
import Wrapper from '../UI/Wrapper'
import Main from '../components/Login/Main'
import Sidebar from '../components/Common/Sidebar'
import Left from '../components/AfterLogin/Left'
import RightMobile from '../components/Common/RightMobile'
import Right from '../components/Login/Right'

const AfterLogin = () => {
    
    return (
        <Wrapper>
            <div className="flex">
                <Sidebar />
                <Main>
                    <Left />
                    <Right/>
                    <RightMobile />
                </Main>
                <div className="fixed text-xs xs:hidden bottom-[40px] font-ubuntu xs:w-[200px] right-[40px]">
                Please note that your data is protected and won’t be used anywhere else.
                </div>
            </div>
        </Wrapper>
    )
}

export default AfterLogin