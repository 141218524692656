import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import leftArrow from '../../../../../assets/images/arrow-left-grey.png'
import close from '../../../../../assets/images/close.png'
import { mediaModalActions } from '../../../../../services/mediaModalSlice';
import SimpleBar from 'simplebar-react';
import { useGetSharedMediaQuery } from '../../../../../services/chatApi';
import { BASE_URL } from '../../../../../keys'
import Button from '../../../../../UI/Button';
import noData from '../../../../../assets/images/no-data.gif';
import MediaLoading from './MediaLoading';
import { useEffect } from 'react';
import { useGetCurrentUserQuery } from '../../../../../services/authApi';

const MediaModal = ({ setInitialSlide, pageNo, setPageNo }) => {
    const dispatch = useDispatch();
    const coachId = useSelector(state => state.coachReducer.coach_id);
    const clientId = useSelector(state => state.chatUserReducer.user.user_id);
    const { data: user } = useGetCurrentUserQuery()
    // console.log('user_type', user?.data?.user_type)

    const [userId, setuserId] = useState(null)

    // console.log(coachId, clientId, "media*****")

    useEffect(() => {
        if (user?.data?.user_type === 'client') {
            setuserId(coachId)
        } else if (user?.data?.user_type === 'coach') {
            setuserId(clientId)
        }
    }, [coachId, clientId])

    const { data: media, isLoading, isSuccess } = useGetSharedMediaQuery({ userId, pageNo });

    // console.log(media?.data)
    return (
        <div className="bg-[#4040407c] font-ubuntu fixed flex justify-center items-center w-screen h-screen top-0 left-0">
            <div className="w-1/4 bg-white rounded-3xl overflow-hidden pb-10">

                <div className="bg-heavy h-10 flex px-3 justify-between items-center">
                    <img
                        onClick={() => {
                            dispatch(mediaModalActions.toggleModal("profileOpen"))
                        }}
                        src={leftArrow} alt="" className="w-4 h-4 cursor-pointer object-contain" />
                    <p className="font-[500] text-[1.1rem]">Media</p>
                    <img
                        onClick={() => {
                            dispatch(mediaModalActions.toggleModal("profileOpen"))
                        }}
                        src={close} className="w-4 h-4 cursor-pointer object-contain" alt="" />
                </div>

                {isLoading &&
                    <SimpleBar className="w-full h-[500px] py-10">
                        <MediaLoading />
                    </SimpleBar>
                }
                {media?.data?.length === 0 &&
                    <div className="w-full text-center">
                        <img src={noData} alt="" className="w-full h-auto" />
                        <h1 className="my-2 text-lightOrange">Sorry! No media found.</h1>
                    </div>
                }
                {!isLoading && isSuccess && media?.data?.length > 0 &&
                    <SimpleBar className="w-full h-[500px] py-5">
                        <div className="flex flex-wrap justify-center w-full gap-2">
                            {media?.data?.map((item, i) => (
                                <div key={item.created_at}
                                    onClick={() => {
                                        setInitialSlide(i);
                                        dispatch(mediaModalActions.toggleModal("carouselModal"));
                                    }}
                                    className="w-40 cursor-pointer overflow-hidden h-40 rounded-2xl bg-gray-300">
                                    <img src={BASE_URL + item.file_link} className="w-full h-full object-cover hover:scale-125 transition-all ease-in-out" alt="" />
                                </div>
                            ))}


                        </div>
                        {media?.data?.length >= 10 ?
                            <div className="flex w-full justify-center gap-3">
                                <Button
                                    onClick={() => setPageNo(prev => prev + 1)}
                                    className="bg-lightOrange mx-auto my-3 font-ubuntu text-white font-bold px-5">Load Next</Button>

                                {pageNo > 1 &&
                                    <Button
                                        onClick={() => setPageNo(prev => prev - 1)}
                                        className="bg-lightOrange mx-auto my-3 font-ubuntu text-white font-bold px-5">Load Previous</Button>
                                }

                            </div>

                            :
                            <Button
                                onClick={() => setPageNo(prev => prev - 1)}
                                className="bg-lightOrange mx-auto my-3 font-ubuntu text-white font-bold px-5">Load Previous</Button>
                        }


                    </SimpleBar>
                }


            </div>
        </div>
    )
}

export default MediaModal