import React from 'react'
import Wrapper from '../UI/Wrapper'
import Main from '../components/Login/Main'
import Sidebar from '../components/Common/Sidebar'
import Left from '../components/Login/Left'
import Right from '../components/Login/Right'
import RightMobile from '../components/Common/RightMobile'

const Login = () => {

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={true} />
                <Main>
                    <Left />
                    <Right />
                    <RightMobile />
                </Main>
                <div className="fixed text-xs bottom-[40px] xs:hidden font-ubuntu xs:w-[200px] right-[40px]">
                    Please note that your data is protected and won’t be used anywhere else.
                </div>
            </div>
        </Wrapper>
    )
}

export default Login