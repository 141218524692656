import React, { useState, useRef } from 'react'
import Button from '../../../../UI/Button'
import ModalWrapper from '../../../../UI/ModalWrapper'
import { useReportClientMutation } from '../../../../services/coachScreenApi';
import { ToastContainer, toast } from 'react-toastify'

const ReportModal = ({ onCloseModal, currentChatUser, userFlaggedStatus }) => {

    // console.log('currentChatUser from ReportModal', currentChatUser?.user_name);
    console.log('userFlaggedStatus', userFlaggedStatus);

    const [report, { isLoading }] = useReportClientMutation();
    const form = useRef(null)

    const [reason, setReason] = useState('');

    const handleChange = event => {
        setReason(event.target.value);
    };

    const handleReportUser = async (e) => {
        e.preventDefault();
        if (reason.trim().length !== 0) {
            // console.log('reason is NOT empty');
            const formData = new FormData(form.current);
            formData.append('client_id', currentChatUser?.user_id);
            const response = await report(formData);
            const data = await response.data;

            try {
                if (data.code === 'Success') {
                    toast.success(data.message, { autoClose: 2000 });
                    setTimeout(onCloseModal, 2000);
                    if (data.message === 'User has been flagged successfully!') {
                        localStorage.setItem('isReported', 1);
                    }
                    if (data.message === 'User has been unflagged successfully!') {
                        localStorage.setItem('isReported', 0);
                    }
                }
            } catch (error) {
                toast.error("Some error occured! Please try again.");
            }
        } else {
            // console.log('reason is empty');
            toast.error('Please add a reason and submit.');
        }
    }

    return (
        <ModalWrapper>
            <ToastContainer />
            <div className="bg-white rounded-3xl text-xl font-ubuntu px-12 py-12 w-[35vw]">
                <div className="font-semibold text-center mb-8">
                    Report this Client?
                </div>

                {userFlaggedStatus != 0 &&
                    <div className="font-ubuntu text-[#F76411] text-[14px] text-left !leading-normal mb-10">
                        {currentChatUser?.user_name} is already flagged. You can unflag this user by submitting the form below with a reason.
                    </div>
                }

                <label className="font-ubuntu text-[#868686] text-[14px] text-left !leading-normal">
                    Please state the reason to report {currentChatUser?.user_name}
                </label>

                <form ref={form} onSubmit={handleReportUser} >
                    <div className="flex w-full mt-2">
                        <textarea onChange={handleChange} name="reason" id="reason" placeholder={"Specify"} type="text" className="w-full p-1 text-[#F76411] text-[14px] outline-none border-b border-[#D9D9D9] focus:border-[#F76411] active:border-[#F76411] pr-6 placeholder:text-[#868686] placeholder:text-[12px] mt-3 !leading-normal" rows={4}></textarea>
                    </div>
                    <div className="flex justify-evenly gap-12 w-full mt-4 text-center">
                        <Button className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7">Submit</Button>
                        <Button onClick={onCloseModal} className="bg-[#FFDBB0] text-lightOrange font-ubuntu font-bold py-2 rounded-full min-w-[120px] mt-7">Close</Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
}

export default ReportModal