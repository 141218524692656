import { configureStore } from '@reduxjs/toolkit'
import { authApi } from '../services/authApi'
import { settingsReducer } from '../services/settingsSlice'
import { therapistReducer } from '../services/therapistSlice'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { categoryApi } from '../services/categoryApi'
import { coachApi } from '../services/coachApi'
import { slotReducers } from '../services/slotSlice'
import { chatApi } from '../services/chatApi'
import { bookingReducer } from '../services/bookingSlice'
import { coachReducer } from '../services/coachSlice'
import { userReducer } from '../services/userSlice'
import { mediaModalReducer } from '../services/mediaModalSlice'
import { coachScreenApi } from '../services/coachScreenApi'
import { chatUserReducer } from '../services/chatUserSlice'
import { pinnedReducer } from '../services/pinnedUnpinnedSlice'
import noteSlice from '../services/noteSlice'
import clientSlice from '../services/clientSlice'
import notificationSlice from '../services/notificationSlice'

const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, therapistReducer)
const persistedSettingsReducer = persistReducer(persistConfig, settingsReducer)
const persistedCoachReducer = persistReducer(persistConfig, coachReducer)
const persistedUserReducer = persistReducer(persistConfig, userReducer)
const persistedChatUserReducer = persistReducer(persistConfig, chatUserReducer)
const pinnedUnpinnedReducer = persistReducer(persistConfig, pinnedReducer)

const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        settingsReducer: persistedSettingsReducer,
        therapistReducer: persistedReducer,
        slotReducers: slotReducers,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [coachApi.reducerPath]: coachApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer,
        bookingReducer: bookingReducer,
        coachReducer: persistedCoachReducer,
        userReducer: persistedUserReducer,
        mediaModalReducer: mediaModalReducer,
        [coachScreenApi.reducerPath]: coachScreenApi.reducer,
        chatUserReducer: persistedChatUserReducer,
        pinnedReducer: pinnedUnpinnedReducer,
        notes: noteSlice,
        client: clientSlice,
        notifications: notificationSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
export const persistor = persistStore(store)
export default store
