import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css"

const Container = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, [])
  
  return (
    <div className={`lp-lg:max-w-[1150px] lp-xl:max-w-[1300px] mx-auto px-4 ` + props.className}>
      {props.children}
    </div>
  );
};

export default Container;
