import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenProfileMutation } from '../../../services/chatApi'
import ProfileOpenModal from '../../Client/Chat/Right/ProfileOpenModal'
import { mediaModalActions } from '../../../services/mediaModalSlice'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import { BASE_URL } from '../../../keys'

const ClientInfo = ({ selectedClient }) => {
    //to get client details to display on the modal
    // const [currentUser, setCurrentUser] = useState({}) //vinodh
    const [currentUser, setCurrentUser] = useState('') //vivek (need testing)

    // console.log('selectedClient from clientinfo', selectedClient);

    // for client information modal
    const currentModal = useSelector((state) => state.mediaModalReducer.currentModal);
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();

    const [openProfile, { isLoading, isError }] = useOpenProfileMutation()

    const profileOpenHandler = async () => {
        const formData = new FormData()
        formData.append('person_id', selectedClient)
        // formData.append('person_id', 2)

        const response = await openProfile(formData)
        if (response.data) {
            // console.log('ClientInfo from clientinfo', response.data.data)
            setCurrentUser(response.data.data)
        }
    }
    useEffect(() => {
        profileOpenHandler()
    }, [selectedClient])

    //to determine Mr./ Ms. based on gender info
    // const prefix = currentUser?.gender === 'Male' ? 'Mr.' : 'Ms.'

    // console.log(currentUser.length, 'currentUser.length');
    // console.log('currentUser.profile_pic', currentUser.profile_pic);

    return (
        <aside className="bg-[#FDE9D8] border-[1.5px] border-[#FFDBB0] rounded-2xl w-[250px] p-0 text-center flex flex-col justify-center basis-2/4">
            <>
                <div className="w-full font-bold text-[1.3rem] font-constantia leading-8">
                    Client Information
                </div>
                <div className="m-auto border-[2px] w-[100px] h-[100px] rounded-full bg-[#FDF5EE] border-[#FFDBB0] my-5">
                    <img
                        src={`${currentUser.profile_pic}` != '' ?
                            `${BASE_URL}${currentUser.profile_pic}` :
                            `${emptyavatar}`}
                        alt="profile image"
                        className="w-[100px] h-[100px] rounded-full object-cover"
                    />
                </div>
                <p className="w-full font-bold text-[1rem] font-ubuntu leading-8 pb-4">
                    {currentUser?.name}
                </p>
                {currentUser?.name &&
                    <button onClick={() => {
                        setModal(true);
                        dispatch(mediaModalActions.toggleModal("profileOpen"))
                    }}
                        type="submit"
                        className="bg-[#FFDBB0] px-4 font-ubuntu items-center  h-[40px] rounded-[20px] font-bold text-[#F77F1F] w-[155px] text-center mx-auto"
                    >
                        See More
                    </button>
                }
            </>
            {modal && <ProfileOpenModal currentUser={currentUser} closeModal={() => setModal(false)} />}
        </aside>
    )
}

export default ClientInfo