import React from "react"
import Sidebar from "../../components/Common/Sidebar"
import Main from "../../components/FormScreen/Main"
import Wrapper from "../../UI/Wrapper"
import Left from "../../components/Client/Profile/Left"
import Right from "../../components/Client/Profile/Right"

const Profile = () => {
  return (
    <Wrapper>
      <div className="flex">
        <Sidebar notLoggedIn={false} />

        <Main>
          <div className="flex flex-row justify-between items-center w-full space-x-5 max-w-[90%] mx-auto">
            <Left />
            <Right />
          </div>
        </Main>
      </div>
    </Wrapper>
  )
}

export default Profile
