import React from 'react'
import { useSelector } from 'react-redux'

const UserDetail = () => {
  const coachName = useSelector((state) => state.coachReducer.coachName)
  return (
    <div className="flex gap-5 items-center relative">
        <div className="border-orange border-2 w-5 h-5 rounded-full"></div>
        <div className="absolute w-6 h-6 bg-heavy rounded-full left-[-15px] top-[1px]"></div>
        <h1 className="text-md font-semibold" title={coachName}>{coachName?.length > 12 ? coachName.substr(0,12)+"..." : coachName}</h1>
    </div>
  )
}

export default UserDetail