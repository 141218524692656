import React from "react"
import closeIcon from "../../../../assets/images/close x.svg"

const ModalWrapper = (props) => {
	return (
		<div
			className={
				"absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[9999] min-w-[450px] min-h-[200px] bg-white rounded-[15px] " +
				props.className
			}
		>
			<div className="bg-[#FDF5EE] p-2 text-center rounded-t-[15px] font-ubuntu font-bold text-[16px] relative">
				{props.title}
				<span
					className="absolute w-[20px] h-[20px] object-contain top-2.5 right-3 cursor-pointer"
					onClick={() => {
						props.onCloseProfileModal()
					}}
				>
					<img
						src={closeIcon}
						alt="close icon"
						className="h-[22px] w-[17.27px] object-contain"
					/>
				</span>
			</div>
			{props.children}
		</div>
	)
}

export default ModalWrapper
