import React, { useState } from 'react'
import Input from '../../Login/Input'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import searchIcon from '../../../assets/images/search-icon.png'
import coachNotes from '../../../assets/images/notes_sidebar.png'
import { useGetUsersInChatQuery } from '../../../services/chatApi'
import { useDispatch, useSelector } from 'react-redux'
import { chatUserActions } from '../../../services/chatUserSlice'
import { toggleClient } from '../../../services/clientSlice'
import { useEffect } from 'react'
import moment from 'moment'
import { clientData } from '../Notes/dummyClientData'
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import { timeDifferenceCalculator } from 'time-difference-calculator';
import ReactTimeAgo from 'react-time-ago'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../../../services/api'
import { BASE_URL } from '../../../keys'

const ClientList = ({ socket, clientListing, clientListingLoading }) => {

    const { client__id } = useParams()
    const navigate = useNavigate()

    // console.log('Chat List from Coach Client List Page', clientListing)

    const [searchClient, setSearchClient] = useState('')
    const [leftClientList, setLeftClientList] = useState(clientListing)

    const { data, isLoading, isSuccess } = useGetUsersInChatQuery()
    // console.log('useGetUsersInChatQuery', data);

    // console.log('@@@@@@DATA', data?.data)
    // console.log(
    //     '@@@@@@FILTER',
    //     data?.data?.filter((data) => data.user_id == client__id)
    // )


    // console.log('client__id', client__id);

    useEffect(() => {
        const filteredClientList = clientListing?.filter((client) =>
            client?.user_name.toLowerCase().includes(searchClient.toLowerCase())
        )

        setLeftClientList(filteredClientList)
    }, [clientListing, searchClient])


    const dispatch = useDispatch()
    const currentChatUser = useSelector((state) => state.chatUserReducer.user)
    const roomId = useSelector((state) => state.client.roomId)
    // const [lastMsg, setlastMsg] = useState("");
    const [userList, setuserList] = useState([])

    // console.log(roomId, "roomId in client list");

    // console.log('currentChatUser', currentChatUser)

    useEffect(() => {
        socket.emit('join_room', roomId)
    }, [roomId])


    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         console.log(data, "event triggered")
    //         setlastMsg(data.message)
    //     })
    // }, [socket])
    //storing the room id into the redux store
    useEffect(() => {
        dispatch(
            toggleClient({
                roomId:
                    data?.data[0]?.coach_email + '_' + data?.data[0]?.user_email,
            })
        )
        setuserList(data?.data)
    }, [data])

    // console.log('leftClientList[0]', leftClientList[0]);

    useEffect(() => {
        if (leftClientList && !client__id) {
            dispatch(
                chatUserActions.toggleCurrentUser(leftClientList[0])
            )
        }
    }, [])


    useEffect(() => {
        if (client__id) {
            data?.data
                ?.filter((data) => data.user_id == client__id)
                .map((item) => {
                    // console.log('item', item)
                    // console.log('leftClientList[0]', leftClientList[0])
                    dispatch(
                        chatUserActions.toggleCurrentUser(item)
                    )
                })
        }
    }, [client__id, data])

    const getClientNotes = (client_id) => {
        navigate(`/coach-notes/${client_id}`)
    }

    //setting the last chat user to the top
    // useEffect(() => {
    //     socket.on('message_triggered', (data) => {
    //         console.log("event triggerd.....")
    //         api.get(`common/list-of-users-in-chat/`, {
    //             headers: {
    //                 authorization: `Bearer ${localStorage.getItem('livtoken')}`,
    //             },
    //         }).then((res) => {
    //             console.log(res, "RES after event trigger****")
    //             setLeftClientList(res?.data.data)
    //             dispatch(
    //                 toggleClient({
    //                     name: res?.data.data[0].coach_name,
    //                     roomId: `${res?.data.data[0].coach_email}_${res?.data.data[0]?.user_email}`,
    //                     coach_category: res?.data.data[0].coach_category,
    //                     coach_id: res?.data.data[0].coach_id,
    //                     sessionDate: res?.data.data[0].session_date,
    //                     sessionTime: res?.data.data[0].session_time,
    //                     isRelationPaused:
    //                         res?.data.data[0].is_relation_paused,
    //                     coach_category_id:
    //                         res?.data.data[0].coach_category_id,
    //                 })
    //             )
    //         })
    //     })
    // }, [socket])
    //triggering this api in every 5 seconds because there is no socket event in the django backend for updating the user (temp fix)
    useEffect(() => {
        const listUsers = setInterval(() => {
            api.get(`common/list-of-users-in-chat/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('livtoken')}`,
                },
            }).then((res) => {
                // console.log(res, "RES after event trigger****")
                setLeftClientList(res?.data.data)
            })
        }, 5000);
        return () => clearInterval(listUsers);
    }, [leftClientList]);


    return (
        <aside className="w-[350px] p-4 h-[92%] mt-12 ml-8 2xl:ml-12 overflow-hidden ">
            <h1 className="text-xl">Client List</h1>

            {/* Text Search */}
            <div className="flex bg-white rounded-3xl py-2 px-4 outline-none border-[#FFC289] border mt-3">
                <div className="w-[30px] mt-1">
                    <img src={searchIcon} alt="" className="h-5" />
                </div>
                <input
                    className="outline-none w-full"
                    name="search-text"
                    id="search-text"
                    placeholder="Search"
                    onChange={(e) => setSearchClient(e.target.value)}
                />
            </div>

            {/* Client Loop */}
            <SimpleBar className='lg:h-[50vh] xl:h-[72vh] 2xl:h-[77vh] 3xl:h-[80vh]' forceVisible="y" autoHide={false}>

                <div className="w-full">
                    {clientListingLoading && (
                        <>
                            {clientData.map((_, i) => (
                                <div
                                    key={i}
                                    className="border bg-heavy border-heavyBorder shadow rounded-md p-4 max-w-sm w-full mx-auto my-3"
                                >
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="rounded-full bg-heavyBorder h-10 w-10"></div>
                                        <div className="flex-1 space-y-6 py-1">
                                            <div className="h-2 bg-heavyBorder rounded"></div>
                                            <div className="space-y-3">
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="h-2 bg-heavyBorder rounded col-span-2"></div>
                                                    <div className="h-2 bg-heavyBorder rounded col-span-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {!clientListingLoading && leftClientList?.length === 0 && (
                        <p className="mt-40 text-center">No Clients Found!</p>
                    )}

                    {/* {!isSuccess && */}
                    {!clientListingLoading && isSuccess &&
                        leftClientList?.map((client, index) => (
                            // data.data?.map((client, index) => (
                            <div
                                key={client.id}
                                onClick={() => {
                                    dispatch(
                                        chatUserActions.toggleCurrentUser(client)
                                    )
                                    dispatch(
                                        toggleClient({
                                            name: client.coach_name,
                                            roomId: `${client.coach_email}_${client?.user_email}`,
                                            coach_category: client.coach_category,
                                            coach_id: client.coach_id,
                                            sessionDate: client.session_date,
                                            sessionTime: client.session_time,
                                            isRelationPaused:
                                                client.is_relation_paused,
                                            coach_category_id:
                                                client.coach_category_id,
                                        })
                                    )
                                    socket.emit('leave_room', roomId)
                                }}
                                className={`flex rounded-[40px] w-full p-1 mt-5 ${currentChatUser?.user_name == client.user_name
                                    ? 'bg-white'
                                    : ''
                                    }`}
                            >
                                <div className="flex cursor-pointer relative font-ubuntu rounded-[40px] w-full py-4 px-4 mt-1">
                                    <div className=" overflow-hidden flex justify-center items-center border w-[55px] h-[55px] rounded-full bg-[#FDE9D8] border-[#FFDBB0] ">
                                        <img
                                            src={`${client.user_profile_pic}` !== '' ?
                                                `${BASE_URL}${client.user_profile_pic}` :
                                                `${emptyavatar}`}
                                            alt="profile image"
                                            className={client.is_relation_paused === "yes" ? " w-[55px] h-[55px] rounded-full blur-[3px] grayscale object-cover" : " w-[55px] h-[55px] rounded-full object-cover"}
                                        />
                                    </div>
                                    <div className="pl-3 flex-[2]">
                                        <p className={client.is_relation_paused === "yes" ? "text-md mb-1 font-medium text-gray-400" : "text-md mb-1 font-medium"}>
                                            {client.user_name}
                                        </p>
                                        <p className="text-sm  leading-4 text-gray-500">
                                            {
                                                client?.last_message?.message.substring(0, 10)
                                            }...
                                        </p>
                                    </div>
                                    <img
                                        className="w-[20px] h-[20px] !mt-[-5px] absolute right-4 flex-1"
                                        src={coachNotes}
                                        alt="Notes"
                                        onClick={() => getClientNotes(client.user_id)}
                                    />

                                    <div className="mt-3 absolute bottom-3 right-2 flex-1">
                                        <p className="text-[10px] text-gray-500">
                                            {
                                                moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(client.session_date)), 'days') > 0 ?
                                                    null : <span> Next Session in</span>
                                            }
                                        </p>

                                        <p className="text-xs font-bold text-right">
                                            {moment(moment().format("YYYY-MM-DD")).diff(moment(moment().format(client.session_date)), 'days') > 0 ?
                                                <span className="text-red-800">Session Completed</span> :
                                                <ReactTimeAgo date={client?.session_date + " " + client?.session_time} locale="en-US" />
                                            }
                                        </p>
                                    </div>
                                </div >
                            </div >
                        ))}
                </div >
            </SimpleBar >
        </aside >
    )
}

export default React.memo(ClientList)
