import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useOpenProfileMutation } from '../../../services/chatApi';
import ProfileOpenModal from './Right/ProfileOpenModal';
import { mediaModalActions } from '../../../services/mediaModalSlice';
import emptyavatar from '../../../assets/images/emptyavatar.webp'
import { BASE_URL } from '../../../keys';


const ClientInfo = () => {
    const coachName = useSelector((state) => state.coachReducer.coachName);
    const coachId = useSelector((state) => state.coachReducer.coach_id);
    const [currentUser, setCurrentUser] = useState({});
    const currentModal = useSelector((state) => state.mediaModalReducer.currentModal);
    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const [openProfile, { isLoading, isError }] = useOpenProfileMutation();

    const profileOpenHandler = async () => {
        const formData = new FormData();
        formData.append('person_id', coachId);

        const response = await openProfile(formData);
        if (response.data) {
            setCurrentUser(response.data.data)
        }
    }
    useEffect(() => {
        profileOpenHandler();
    }, [coachId]);

    return (
        <aside className="bg-[#FDE9D8] border-[1.5px] border-[#FFDBB0] rounded-2xl w-[250px] mt-4 p-0 text-center flex flex-col justify-center basis-2/4">
            <>
                <div className="w-full font-bold text-[1.3rem] font-constantia leading-8">
                    Coach Information
                </div>
                <div className="m-auto border-[2px] w-[100px] h-[100px] rounded-full bg-[#FDF5EE] border-[#FFDBB0] my-5">
                    <img
                        src={`${currentUser.profile_pic}` != 0 ?
                            `${BASE_URL}${currentUser.profile_pic}` :
                            `${emptyavatar}`}
                        alt="profile image"
                        className="w-[100px] h-[100px] rounded-full object-cover"
                    />
                </div>
                <p className="w-full font-bold text-[1rem] font-ubuntu leading-8 pb-4">{coachName}</p>
                <button
                    onClick={() => {
                        setModal(true);
                        dispatch(mediaModalActions.toggleModal("profileOpen"))
                    }}
                    className="mx-auto bg-[#FFDBB0] px-4 font-ubuntu items-center text-center h-[40px] rounded-[20px] w-[155px] font-bold text-lightOrange">
                    See More
                </button>
                {modal && <ProfileOpenModal currentUser={currentUser} closeModal={() => setModal(false)} />}
            </>
        </aside>
    )
}

export default ClientInfo