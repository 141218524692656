import React from 'react'

const PrivacyPolicy = () => {
    return (
        <section className="bg-white w-[65%] h-[75vh] overflow-y-scroll rounded-[20px] py-6 px-12">
            <h1 className="text-2xl mb-[40px] mt-3 text-black font-ubuntu font-bold">Privacy Policy</h1>

            <div className='w-[100%]'>
            <p className='text-[15px] my-2'>This policy describes what information we collect when you use Hapsmiths sites, services, therapy, and content (“Services”). It also provides information about how we store, transfer, use, and delete that information, and what choices you have with respect to the information. This policy is designed to ensure that we safely handle your personal data in accordance with relevant regulations and legislations of India.</p>
            <p className='text-[15px] my-2'>Please review our Privacy Policy carefully, as your use of Services constitutes your agreement to it. You are not permitted to allow any family member or relative to use your user account. If you provide any Personal Information of any family member or relative through your user account for the purposes of the Services offered, you acknowledge and agree that such conduct constitutes the affirmative acknowledgement and agreement of the particular family member and their consent to these Terms and Conditions and our Privacy Policy.</p>
            <p className='text-[15px] my-2'>This policy applies to Hapsmith’s main website, Hapsmith’s content portals, and applications (collectively “the platform”), as well as other interactions you may have with Hapsmith (e.g. customer support conversations etc) and is supplementary to our Terms and Conditions. In the event of any inconsistency or conflict between this Policy and our Terms and Conditions, this Policy will govern.</p>
            <p className='text-[15px] my-2'>You have been provided an opportunity to freely access the policy and agree to our Terms and Conditions before using our Services, having regard to Indian Contract Act, 1872 and Information Technology Act, 2000.</p>

            <ul className='reset text-[15px]'>
                <li className='mb-6'><strong>1. Definitions:<br /></strong>
                <p className='text-[15px] my-2'>“User or You” A patient, his/her representatives or affiliates, searching for Practitioners through the aforementioned app or any User of the App (“End-User”, “you” or “User”);</p>
                <p className='text-[15px] my-2'>“Practitioner” means a person engaged by the Company after verifying due qualifications and is deemed to be proficient in their respective fields to provide Services herein.</p>
                </li>

                <li className='mb-6'><strong>2. Data Use:<br /></strong>
                <p className='text-[15px] my-2'>
                    We in Hapsmith are committed to safeguarding the privacy of our users. Our business model is to provide a service to users who need to access mental health support that is paid for by their employer. Therefore, our business model does not rely on widespread collection of general user data. We will only collect and process information that we need to deliver the service to you, and to continue to maintain and develop the service.
                </p>

                <ul className='pl-[30px] text-[15px]'>
                    <li className='mb-6'><strong>2.1 User account information: </strong>
                    If you choose to book therapy on Hapsmith, you will have to provide your name and pronoun, a valid email address and phone number, your age, your business role, account password, account ID and (optionally) your post code or the area where you live. You must enter a valid phone number so that Hapsmith inc. may send you verification codes to verify your account and other important text notifications regarding any activity in your account. If at any time you have changed or decide to change your phone number, you agree to be solely responsible for updating your new phone number information with us. During the process of booking consultations offered by us, we also ask for a bit of information about you, such as what brought you to Hapsmith, what you expect from your Practitioners, what you think your Practitioner assigned needs to know about you or your past that would allow them to help you most effectively and whether you have any experience of therapy. This information helps our Practitioners to prepare for the session, so as to make the session as helpful to you as possible. Thus, we require this information in order to deliver the Service to you as user. Processing this information is required for fulfilling the contract we entered into with you, at your request (our Terms and Conditions).
                    </li>

                    <li className='mb-6'><strong>2.2 User analytics: </strong>
                    Like most digital services, our systems automatically collect information about how you use the Hapsmith Platform. This may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your use of the Hapsmith Platform. The source of the usage data is our analytics tracking system or the technical log data. We require this information in order to analyse the way people use the Platform and in order to build aggregate quantitative usage reports for the company that paid for your access to the service. You cannot be identified from this information. The legal basis for this processing is for namely using this data for the purpose of ensuring the proper administration of our website and business, analyzing the use of the website and services, monitoring and improving our website and services, improving the user experience, preventing abuse, and assisting users with support inquiries.
                    </li>

                    <li className='mb-6'><strong>2.3 User personal information: </strong>
                    This is information you give us during your use of the Platform. Personal Information may include but not limited to Practitioner questions, personal details, the details of your employment, relationships, health or personal matters, Transmission of summary of patient complaints and supplementary data including images etc. Our Practitioners have been instructed to handle the data with care and only store it to provide Service to the users.
                    </li>
                </ul>

                <p className='text-[15px] my-2'>We may process any of your personal data identified in this policy where necessary for administrative purposes including in the exercise or defence of legal claims. The legal basis for this processing is our legitimate interests, namely for administrative record keeping, processing transactions and maintaining business records or for the protection and assertion of our legal rights. </p>
                </li>

                <li className='mb-6'><strong>3. Acceptable use:<br /></strong>
                <p className='text-[15px] my-2'>
                    Personal data collected may be used to respond to (a) any medical emergency involving a threat to the life or a severe threat to the health of the data principal or any other individual; (b) to undertake any measure to provide medical treatment or emergency health services and (c) Upon Government order, competent authority or court of Law
                </p>
                </li>

                <li className='mb-6'><strong>4. Right to Erase:<br /></strong>
                <p className='text-[15px] my-2'>
                    User has the right to withdraw their previously given consent and have the personal information erased from Company’s system for the data that is no longer necessary for the purpose that it is shared. However, doesn’t mean that Your data will be erased immediately, it will still be stored at Company’s facility in order to comply with numerous statutory obligations and Applicable Laws. After this period has elapsed, Your Personal Data will be deleted from Company’s records.
                </p>
                </li>

                <li className='mb-6'><strong>5. Data Security and Breaches:<br /></strong>
                <p className='text-[15px] my-2'>
                    The information collected as per this Policy is protected by security controls consistent with Industry Standards and the Company has adopted all viable Security measures to protect the data collected.
                </p>
                </li>

                <li className='mb-6'><strong>6. Cookies:<br /></strong>
                <p className='text-[15px] my-2'>
                    are small data files sent to your browser and stored on your hard drive. We use cookies to offer customized services and collect information on Internet usage. These files do not collect Personally Identifiable Information about you. Rather, they operate by identifying your browser, saving information regarding your use of a Web site, and providing you with personalized services. For example, a cookie can remember your password for logon procedures and Services you have selected during a visit to the platform. The result is a more personalized and enjoyable experience. You can set your browser to disable cookies, but by doing so, you may not be able to enjoy the personalized features offered by our Platform.
                </p>
                </li>

                <li className='mb-6'><strong>7. Visiting an Official Hapsmith Page on Third-Party Websites/Applications:<br /></strong>
                <p className='text-[15px] my-2'>
                    We maintain accounts on third-party websites, such as social media sites, as tools to better interact with the public. Your activity on those third-party websites is governed by the security and privacy policies of those sites. Users of third-party websites are often sharing information with the general public, user community, and/or the third-party operating the website. These actors may use this information in a variety of ways. You should review the privacy policies of third-party websites before using them and ensure that you understand how your information may be used. You should also adjust privacy settings on your account on any third-party website to match your preferences.
                </p>
                <p className='text-[15px] my-2'>Hapsmith Accounts</p>
                <p className='text-[15px] my-2'>Common third-party websites in use include: Facebook Privacy Policy<br />
                    Instagram Privacy Policy<br />
                    Twitter Privacy Policy<br />
                    YouTube Privacy Policy
                </p>
                </li>

                <li className='mb-6'><strong>8. Prohibited Activities:<br /></strong>
                <ul className='pl-[30px] text-[15px]'>
                    <li className='mb-6'><strong>8.1 User of Platform acknowledges and agrees not to host, display, upload, modify, publish, transmit, update or share any information that:</strong>
                    <ol type='a' className='reset text-[15px]'>
                        <li>belongs to another person and to which the user does not have any right to;</li>
                        <li>is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                        <li>harm minors in any way;</li>
                        <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                        <li>violates any law for the time being in force;</li>
                        <li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                        <li>impersonate another person;</li>
                        <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                        <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                    </ol>

                    <p className='text-[15px] my-2'>upon obtaining knowledge by itself or been brought to actual knowledge by an affected person in writing or through email signed with electronic signature about any such information as mentioned, shall act within thirty-six hours and where applicable, work with User or owner of such information to disable such information that is in contravention of this Clause. Further, Hapsmiths shall preserve such information and associated records for at least ninety days for investigation purposes.</p>
                    <p className='text-[15px] my-2'>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to  </p>
                    </li>

                    <li>
                    Grievance Redressal Officer:<br />
                    Paul John <br />
                    <a href="tel:+918971829648" className='underline '>+91 8971829648</a><br />
                    <a href="mailto:paul@hapsmiths.com" className='underline '>paul@hapsmiths.com</a>
                    </li>
                </ul>
                </li>

                <li className='text-center'><strong>These Terms were last updated on January, 2023 and are deemed effective as of this date.</strong></li>
            </ul>
            </div>
        </section>
    )
}

export default PrivacyPolicy