import React from 'react'
import Wrapper from '../../UI/Wrapper'
import Sidebar from '../../components/Common/Sidebar'
import Main from '../../components/FormScreen/Main'
import Activities from '../../components/Client/Welcome/Activities'
import Bubble from '../../components/Client/Welcome/Bubble'
import { useDispatch } from 'react-redux'
import { therapistActions } from '../../services/therapistSlice'

const Welcome = () => {
    const dispatch = useDispatch()
    const handleClick = (color) => {
        dispatch(therapistActions.setBG(`bg-[${color}]`))
    }

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={false} />
                <Main>
                    
                   {/*  <div className='grid grid-cols-8 gap-6 '>
                        
                        <div className='col-span-2' ><Activities /></div>
                        <div></div>
                     
                        <div className='col-span-4'><Bubble handleClick={handleClick} /></div>
                        <div></div>
                    </div> */}
                    <div className='flex space-x-20 mx-auto '>
                        <Activities />
                        <Bubble handleClick={handleClick} />


                    </div>
                    
                    
                    
                   
                </Main>
            </div>
        </Wrapper>
    )
}

export default Welcome
